import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/login.css';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';

type Props = {};

const ResetPassword: React.FC<Props> = () => {
    ReactGA.initialize('G-677WMVXWLS');

    const { token } = useParams();

    const authenticated = useSelector((state: RootState) => state.authenticated);
    let authenticatedStorage = localStorage.getItem('authenticated');
    let [loginFormState, setLoginFormState] = useState('ResetAccount');

    const dispatch = useDispatch();

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    useEffect(() => {
        if (authenticatedStorage === 'true') {
            dispatch({ type: 'SET_AUTHENTICATED', payload: true });
        }
    }, [authenticated, dispatch, authenticatedStorage, loginFormState]);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: 'ResetPassword' });
    });

    function handleAPIResult(result: { success: any; failed: any; message: String }) {
        if (result.success) {
            toast.success(`${result.message}`, {
                autoClose: 1000,
            });
        } else if (result.failed) {
            toast.error(`${result.message}`, {
                autoClose: 1000,
            });
        }
    }

    const [password, setPassword] = useState('');

    const resetPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        if (!password || !token) {
            toast.error(`Error: password is blank or token is missing`, {
                autoClose: 1000,
            });
        } else {
            const body = {
                password,
                token,
            };

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            };

            fetch('/api/resetPassword/', options)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then((result) =>
                    handleAPIResult({
                        success: true,
                        failed: false,
                        message: 'Success: Reset password has been submitted',
                    })
                )
                .then(() => setLoginFormState('ResetAccountComplete'))
                .catch((error) =>
                    handleAPIResult({
                        success: false,
                        failed: true,
                        message: 'Error: An error occurred while attempting to reset password',
                    })
                );
        }
    };

    const resetPasswordForm = (
        <section className="login-section">
            <form className="login-form">
                <h2 className="login-h2">Reset Password</h2>
                <fieldset className="login-fieldset">
                    <legend className="login-legend">Reset Password</legend>
                    <ul className="login-ul">
                        <li className="login-li">
                            <label className="login-label" htmlFor="password">
                                New Password:
                            </label>
                            <input
                                className="login-input"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </li>
                    </ul>
                </fieldset>
                <button onClick={resetPassword} className="login-button">
                    Submit
                </button>
            </form>
        </section>
    );

    const resetPasswordFormComplete = (
        <section className="login-section">
            <form className="login-form">
                <h2 className="login-h2">Reset Password</h2>
                <fieldset className="login-fieldset">
                    <legend className="login-legend">Reset Password</legend>
                    <ul className="login-ul">
                        <li className="login-li">
                            <label className="login-label" htmlFor="password">
                                Password change submitted. Please try logging in.
                            </label>
                        </li>
                    </ul>
                </fieldset>
            </form>
        </section>
    );

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {loginFormState === 'ResetAccount' && authenticatedStorage !== 'true' ? (
                resetPasswordForm
            ) : (
                <></>
            )}
            {loginFormState === 'ResetAccountComplete' && authenticatedStorage !== 'true' ? (
                resetPasswordFormComplete
            ) : (
                <></>
            )}
        </>
    );
};

export default ResetPassword;
