import { createReducer } from '@reduxjs/toolkit';

const initialSetNumberOfCardsState = 0;

const setNumberOfCardsStateReducer = createReducer(initialSetNumberOfCardsState, {
    SET_SETNUMBEROFCARDS: (state, action) => {
        return action.payload;
    },
});

export default setNumberOfCardsStateReducer;
