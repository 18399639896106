type Props = {};

const PrivacyPolicy = (props: Props) => {
    return (
        <>
            <h1>Privacy Policy</h1>
            <p>
                This privacy policy applies to MyPokellection and governs the collection, use, and
                disclosure of your information. By using the MyPokellection website, you consent to
                the collection, use, and disclosure of your information as described in this privacy
                policy.
            </p>
            <h2>Collection of Information</h2>
            <p>
                MyPokellection may collect personally identifiable information, such as your name,
                email address, and IP address, when you submit a form or comment on the website.
                MyPokellection may also collect non-personally identifiable information, such as
                your browser type, the type of device you are using, and your internet service
                provider, through the use of cookies and other tracking technologies.
            </p>
            <h2>Use of Information</h2>
            <p>
                MyPokellection may use your information to respond to your inquiries, process your
                requests, and improve the website. MyPokellection may also use your information for
                marketing purposes, such as sending you newsletters or promotional materials.
            </p>
            <h2>Disclosure of Information</h2>
            <p>
                MyPokellection may disclose your information to third parties in order to provide
                the services requested by you, such as processing a payment or fulfilling an order.
                MyPokellection may also disclose your information to third parties for marketing
                purposes, such as displaying targeted advertisements on the website.
            </p>
            <p>
                MyPokellection uses Google Analytics to collect and analyze information about
                website usage. Google Analytics collects information such as how often users visit
                the MyPokellection website, what pages they visit, and what other websites they used
                prior to coming to the MyPokellection website. We use the information we get from
                Google Analytics to improve the MyPokellection website. Google Analytics collects
                only the IP address assigned to you on the date you visit the MyPokellection
                website, rather than your name or other identifying information. We do not combine
                the information collected through the use of Google Analytics with personally
                identifiable information. Although Google Analytics plants a permanent cookie on
                your web browser to identify you as a unique user the next time you visit the
                MyPokellection website, the cookie cannot be used by anyone but Google. Google's
                ability to use and share information collected by Google Analytics about your visits
                to MyPokellection is restricted by the{' '}
                <a href="https://www.google.com/analytics/terms/us.html">
                    Google Analytics Terms of Use
                </a>{' '}
                and the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>. You
                can prevent Google Analytics from recognizing you on return visits to this
                MyPokellection website by disabling cookies on your browser.
            </p>
            <h2>Security of Information</h2>
            <p>
                MyPokellection takes reasonable measures to protect your information from
                unauthorized access, use, or disclosure. However, no website can fully protect your
                information from cyber threats, and MyPokellection cannot guarantee the security of
                your information.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
                MyPokellection may update this privacy policy from time to time. The updated version
                will be posted on this page, so please check back periodically. Your continued use
                of the MyPokellection website after any changes to the privacy policy constitutes
                your acceptance of the updated policy.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about this privacy policy, please contact us
                at <a href="mailto:mypokellection@gmail.com">mypokellection@gmail.com</a>.
            </p>
        </>
    );
};

export default PrivacyPolicy;
