import { createReducer } from '@reduxjs/toolkit';

const initialSeriesNameState = 'Base Series';

const seriesNameReducer = createReducer(initialSeriesNameState, {
    SET_SERIESNAME: (state, action) => {
        return action.payload;
    },
});

export default seriesNameReducer;
