import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Pokemon } from '../../hooks/usePokeDex';
import DesktopPokemonDetail from './DesktopPokemonDetail';
import MobilePokemonDetail from './MobilePokemonDetail';

const PokemonDetail: React.FC = () => {
    const location = useLocation();
    const pokemon = location.state?.pokemon as Pokemon;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    if (!pokemon) {
        return <div>Pokemon not found</div>;
    }

    return isDesktop ? (
        <DesktopPokemonDetail pokemon={pokemon} />
    ) : (
        <MobilePokemonDetail pokemon={pokemon} />
    );
};

export default PokemonDetail;
