import { createReducer } from '@reduxjs/toolkit';

const initialtcgState = 'English';

const TCGStateReducer = createReducer(initialtcgState, {
    SET_TCG_STATE: (state, action) => {
        return action.payload;
    },
});

export default TCGStateReducer;
