import { createReducer } from '@reduxjs/toolkit';

const initialUserNameState = 'Guest';

const userNameReducer = createReducer(initialUserNameState, {
    SET_USERNAME: (state, action) => {
        return action.payload;
    },
});

export default userNameReducer;
