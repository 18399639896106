import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { usePokemonSetFunctions } from '../hooks/usePokemonSetFunctions';
import type { PokemonSet } from '../api/pokemonSetApi';

interface PokemonSetSelectorProps {
    value: number | null;
    onChange: (value: number | null) => void;
}

export const PokemonSetSelector: React.FC<PokemonSetSelectorProps> = ({ value, onChange }) => {
    const { getPokemonSets, loading } = usePokemonSetFunctions();
    const [pokemonSets, setPokemonSets] = useState<PokemonSet[]>([]);
    const [selectedSet, setSelectedSet] = useState<PokemonSet | null>(null);

    useEffect(() => {
        const fetchPokemonSets = async () => {
            const sets = await getPokemonSets();
            setPokemonSets(sets);
        };
        fetchPokemonSets();
    }, [getPokemonSets]);

    useEffect(() => {
        if (value) {
            const set = pokemonSets.find((s) => s.id === value);
            setSelectedSet(set || null);
        } else {
            setSelectedSet(null);
        }
    }, [value, pokemonSets]);

    const handleChange = (_: any, newValue: PokemonSet | null) => {
        setSelectedSet(newValue);
        onChange(newValue ? newValue.id : null);
    };

    return (
        <Autocomplete<PokemonSet>
            options={pokemonSets}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Pokemon Set"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={selectedSet}
            onChange={handleChange}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
