import React, { useState, useEffect, useCallback } from 'react';
import { Box, useScrollTrigger, Zoom, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardCategoryHeader from './CardCategoryHeader';
import CardCategoryFilter, { Filters } from './CardCategoryFilter';
import CardCategoryDisplay from './CardCategoryDisplay';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import { CategoryCard } from '../../../../types/admin';
import LoadingSpinner from '../../../../components/LoadingSpinner';

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const CardCategoryManager: React.FC = () => {
    const { getCardCategoryStats, getCardCategoryPokemonCardData } = useAdminFunctions();
    const [totalCards, setTotalCards] = useState(0);
    const [processedCards, setProcessedCards] = useState(0);
    const [cards, setCards] = useState<CategoryCard[]>([]);
    const [filters, setFilters] = useState<Filters>({
        status: 'unprocessed' as const,
    });
    const [page, setPage] = useState(1);
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const fetchStats = useCallback(async () => {
        const stats = await getCardCategoryStats();
        setTotalCards(stats.totalCards);
        setProcessedCards(stats.processedCards);
    }, [getCardCategoryStats]);

    const fetchCards = useCallback(async () => {
        const fetchedCards = await getCardCategoryPokemonCardData();
        setCards(fetchedCards);
    }, [getCardCategoryPokemonCardData]);

    useEffect(() => {
        const fetchInitialData = async () => {
            await Promise.all([fetchStats(), fetchCards()]);
            setIsInitialLoading(false);
        };

        fetchInitialData();
    }, [fetchStats, fetchCards]);

    if (isInitialLoading) {
        return <LoadingSpinner />;
    }

    const handleFiltersChange = (newFilters: Filters) => {
        setFilters(newFilters);
        setPage(1); // Reset to first page when filters change
    };

    const handleCardsUpdate = (updatedCards: CategoryCard[]) => {
        setCards(updatedCards);
        // Optionally update the stats
        setProcessedCards((prev) => prev + 1);
        setTotalCards((prev) => prev);
    };

    return (
        <>
            <Box sx={{ p: 2 }}>
                <CardCategoryHeader totalCards={totalCards} processedCards={processedCards} />
                <CardCategoryFilter filters={filters} setFilters={handleFiltersChange} />
                <CardCategoryDisplay
                    cards={cards}
                    filterStatus={filters.status}
                    page={page}
                    setPage={setPage}
                    onCardsUpdate={handleCardsUpdate}
                />
            </Box>
            <ScrollTop />
        </>
    );
};

export default CardCategoryManager;
