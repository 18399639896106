import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { usePokeDex } from '../hooks/usePokeDex';
import type { Pokemon } from '../hooks/usePokeDex';

interface PokemonSelectorProps {
    value: number | null;
    onChange: (value: number | null) => void;
}

export const PokemonSelector: React.FC<PokemonSelectorProps> = ({ value, onChange }) => {
    const { pokedex, loading, hasMore, fetchPokedex } = usePokeDex();
    const [selectedPokemon, setSelectedPokemon] = useState<Pokemon | null>(null);

    useEffect(() => {
        if (value) {
            const pokemon = pokedex.find((p) => p.id === value);
            setSelectedPokemon(pokemon || null);
        } else {
            setSelectedPokemon(null);
        }
    }, [value, pokedex]);

    const handleChange = (_: any, newValue: Pokemon | null) => {
        setSelectedPokemon(newValue);
        onChange(newValue ? newValue.id : null);
    };

    const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
        const listboxNode = event.currentTarget;
        if (
            listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50 &&
            !loading &&
            hasMore
        ) {
            fetchPokedex();
        }
    };

    return (
        <Autocomplete<Pokemon>
            options={pokedex}
            groupBy={(option) => `Generation ${option.generation}`}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                    <LazyLoadImage
                        src={option.image}
                        alt={option.name}
                        width={50}
                        height={50}
                        effect="blur"
                        style={{ marginRight: 15 }}
                    />
                    <span style={{ fontSize: '16px' }}>
                        {option.number} - {option.name}
                    </span>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Pokémon"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={selectedPokemon}
            onChange={handleChange}
            loading={loading}
            ListboxProps={{
                style: { maxHeight: '300px', overflowY: 'auto' },
                onScroll: handleScroll,
            }}
        />
    );
};
