import { createReducer } from '@reduxjs/toolkit';

const initialSetNameState = 'Base Set';

const setNameReducer = createReducer(initialSetNameState, {
    SET_SETNAME: (state, action) => {
        return action.payload;
    },
});

export default setNameReducer;
