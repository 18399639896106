import React, { useState } from 'react';
import {
    Paper,
    Typography,
    FormControlLabel,
    Checkbox,
    Box,
    Grid,
    IconButton,
    Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { defaultTypes } from '../../../data/defaultTypes';
import { defaultRarities } from '../../../data/defaultRarities';

type FilterLayoutProps = {
    checkBoxValues: {
        have: boolean;
        need: boolean;
        rarity: { [key: number]: boolean };
        type: { [key: number]: boolean };
    };
    handleCheckBoxChange: (
        section: 'have' | 'need' | 'rarity' | 'type',
        key: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    uniqueRarities: number[];
    uniqueTypes: number[];
    totalFilteredCards: number; // New prop for total filtered cards
};

const DesktopFilterLayout: React.FC<FilterLayoutProps> = ({
    checkBoxValues,
    handleCheckBoxChange,
    uniqueRarities,
    uniqueTypes,
    totalFilteredCards, // New prop
}) => {
    const [expanded, setExpanded] = useState(false);
    const filteredRarities = defaultRarities
        .filter((rarity) => uniqueRarities.includes(rarity.id))
        .sort((a, b) => a.displayIndex - b.displayIndex);

    const filteredTypes = defaultTypes
        .filter((type) => uniqueTypes.includes(type.id))
        .sort((a, b) => a.displayIndex - b.displayIndex);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Paper elevation={4} style={{ width: '100%', padding: '16px 16px 0px 16px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" mr={2}>
                        Filters
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        Total Cards: {totalFilteredCards}
                    </Typography>
                </Box>
                <IconButton onClick={toggleExpanded} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <Grid container spacing={2}>
                    {/* Card Status and Rarity in the same row */}
                    <Grid item xs={12} container spacing={2}>
                        {/* Card Status */}
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Card Status
                            </Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkBoxValues.have}
                                                onChange={handleCheckBoxChange('have', 'have')}
                                            />
                                        }
                                        label="I Have"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkBoxValues.need}
                                                onChange={handleCheckBoxChange('need', 'need')}
                                            />
                                        }
                                        label="I Need"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Rarity */}
                        <Grid item xs={9}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Rarity
                            </Typography>
                            <Grid container spacing={1}>
                                {filteredRarities.map((rarity) => (
                                    <Grid item xs={3} key={rarity.id}>
                                        <FormControlLabel
                                            control={
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    paddingRight="4px"
                                                >
                                                    <Checkbox
                                                        checked={
                                                            checkBoxValues.rarity[rarity.id] ||
                                                            false
                                                        }
                                                        onChange={handleCheckBoxChange(
                                                            'rarity',
                                                            rarity.id.toString()
                                                        )}
                                                    />
                                                    <img
                                                        src={rarity.iconUrl}
                                                        alt={rarity.name}
                                                        width="24"
                                                        height="24"
                                                        style={{ marginLeft: '4px' }}
                                                    />
                                                </Box>
                                            }
                                            label={rarity.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Type below Card Status and Rarity */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Type
                        </Typography>
                        <Grid container spacing={1}>
                            {filteredTypes.map((type) => (
                                <Grid item xs={1.5} key={type.id}>
                                    <FormControlLabel
                                        control={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                paddingRight="4px"
                                            >
                                                <Checkbox
                                                    checked={checkBoxValues.type[type.id] || false}
                                                    onChange={handleCheckBoxChange(
                                                        'type',
                                                        type.id.toString()
                                                    )}
                                                />
                                                <img
                                                    src={type.iconUrl}
                                                    alt={type.name}
                                                    width="24"
                                                    height="24"
                                                    style={{ marginLeft: '4px' }}
                                                />
                                            </Box>
                                        }
                                        label={type.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
        </Paper>
    );
};

export default DesktopFilterLayout;
