import { toast } from 'react-toastify';

export function handleAPIResult(result: { success: any; failed: any; message: String }) {
    if (result.success) {
        toast.success(`${result.message}`, {
            autoClose: 1000,
        });
    } else if (result.failed) {
        toast.error(`${result.message}`, {
            autoClose: 1000,
        });
    } else {
    }
}

export const addCardToCollection = (cardId: any, authParams: string) => {
    // Parse the authParams string back into an object
    const authParamsObj = Object.fromEntries(new URLSearchParams(authParams));

    // Build the request body
    const body = {
        cardId,
        ...authParamsObj,
    };

    // Build the options object for the fetch function
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    // Send the POST request
    fetch(`/api/addCardToCollection?${authParams}`, options)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((result) => {
            handleAPIResult({
                success: true,
                failed: false,
                message: 'Success: Added card to collection!',
            });
        })
        .catch((error) => {
            handleAPIResult({
                success: false,
                failed: true,
                message: 'Failed: Unable to add card to collection',
            });
        });
};

export const removeCardFromCollection = (cardId: any, authParams: string) => {
    // Parse the authParams string back into an object
    const authParamsObj = Object.fromEntries(new URLSearchParams(authParams));

    // Build the request body
    const body = {
        cardId,
        ...authParamsObj,
    };

    // Build the options object for the fetch function
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    // Send the DELETE request
    fetch(`/api/removeCardFromCollection?${authParams}`, options)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((result) =>
            handleAPIResult({
                success: true,
                failed: false,
                message: 'Success: Removed card from collection!',
            })
        )
        .catch((error) =>
            handleAPIResult({
                success: false,
                failed: true,
                message: 'Failed: Unable to remove card from collection',
            })
        );
};
