import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import '../../css/HomePage.css';
import { useNavigate } from 'react-router-dom';

type Props = {};

const HomePage = (props: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    ReactGA.initialize('G-677WMVXWLS');
    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    const handleButtonClick = () => {
        navigate('/login');
    };

    return (
        <>
            <div className="hero-section">
                <div className="hero-header">
                    <h1>Easily track your Pokemon cards</h1>
                    <div className="hero-subtext">
                        <p>
                            Track Pokemon cards by Series, Sets, Print Runs and variants! See
                            current price data, sort by cards you have or need
                        </p>
                    </div>
                    <button className="hero-login-button" onClick={handleButtonClick}>
                        Sign Up for FREE!
                    </button>
                </div>
            </div>
        </>
    );
};

export default HomePage;
