// Updated 1.0.71
export interface Rarity {
    id: number;
    name: string;
    iconUrl: string;
    displayIndex: number; // Add this line
}

export const defaultRarities: Rarity[] = [
    {
        id: 2,
        name: 'Common',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/Common.png',
        displayIndex: 1,
    },
    {
        id: 4,
        name: 'Uncommon',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/Uncommon.png',
        displayIndex: 2,
    },
    {
        id: 6,
        name: 'Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/Rare.png',
        displayIndex: 3,
    },
    {
        id: 5,
        name: 'Double Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/DoubleRare.png',
        displayIndex: 4,
    },
    {
        id: 14,
        name: 'Holo Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/HoloRare2.png',
        displayIndex: 5,
    },
    {
        id: 3,
        name: 'Illustration Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/illustrationRare2.png',
        displayIndex: 6,
    },
    {
        id: 8,
        name: 'Special Illustration Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/SpecialillustrationRare2.png',
        displayIndex: 7,
    },
    {
        id: 9,
        name: 'Hyper Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/HyperRare.png',
        displayIndex: 8,
    },
    {
        id: 7,
        name: 'Ultra Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/icons/question-mark.png',
        displayIndex: 9,
    },
    {
        id: 11,
        name: 'Shiny Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ShinyRare.png',
        displayIndex: 10,
    },
    {
        id: 19,
        name: 'Shiny Holo Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ShinyHoloRare2.png',
        displayIndex: 11,
    },
    {
        id: 12,
        name: 'Shiny Ultra Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ShinyUltraRare2.png',
        displayIndex: 12,
    },
    {
        id: 13,
        name: 'Radiant Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ShinyHoloRare2.png',
        displayIndex: 13,
    },
    {
        id: 15,
        name: 'Secret Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/SecretRare.png',
        displayIndex: 15,
    },
    {
        id: 17,
        name: 'Classic Collection',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/HoloRare2.png',
        displayIndex: 16,
    },
    {
        id: 18,
        name: 'Amazing Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/AmazingRare.png',
        displayIndex: 17,
    },
    {
        id: 20,
        name: 'Prism Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/HoloRare2.png',
        displayIndex: 19,
    },
    {
        id: 21,
        name: 'Rare BREAK',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ShinyHoloRare2.png',
        displayIndex: 20,
    },
    {
        id: 22,
        name: 'Rare Ace',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/RareAce.png',
        displayIndex: 21,
    },
    {
        id: 24,
        name: 'ACE SPEC Rare',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/ACE_SPEC_Rare.png',
        displayIndex: 23,
    },
    {
        id: 16,
        name: 'Promo',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/Promo.png',
        displayIndex: 900,
    },
    {
        id: 23,
        name: 'Missing',
        iconUrl: 'https://mypokellection.com/ImageAssets/RarityIcons/question-mark.png',
        displayIndex: 996,
    },
    {
        id: 10,
        name: 'Code Card',
        iconUrl: 'https://mypokellection.com/ImageAssets/icons/exclamation-mark.png',
        displayIndex: 997,
    },
    {
        id: 1,
        name: 'Unknown',
        iconUrl: 'https://mypokellection.com/ImageAssets/icons/question-mark.png',
        displayIndex: 999,
    },
];
