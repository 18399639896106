import React, { useState, useMemo, useEffect } from 'react';
import { Grid, useMediaQuery, Paper, Fab, useScrollTrigger, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DesktopPokedexHeader from './DesktopPokedexHeader';
import MobilePokedexHeader from './MobilePokedexHeader';
import DesktopFavoritePokemon from './DesktopFavoritePokemon';
import MobileFavoritePokemon from './MobileFavoritePokemon';
import DesktopPokedexFilter from './DesktopPokedexFilter';
import DesktopPokemonCard from './DesktopPokemonCard';
import MobilePokemonCard from './MobilePokemonCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import { RootState } from '../../redux/store'; // Make sure this path is correct
import { useCardFunctions } from '../../hooks/useCardFunctions';
import { usePokeDex, Pokemon } from '../../hooks/usePokeDex';
import LoadingSpinner from '../../components/LoadingSpinner';

// Testing variables - comment out before deploying to production
const TEST_MODE = false; // Set to false for production
const TEST_USER = {
    name: 'TestUser',
    isAuthenticated: true,
    favoritePokemon: {
        name: 'Pikachu',
        image: 'https://mypokellection.com/Pokedex/25.png',
        number: 25,
        generation: 1,
        height: 4,
        weight: 60,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
};

interface HardcodedPokemon {
    id: number;
    name: string;
    generation: number;
    number: number;
    image: string;
    height: number;
    weight: number;
    number_featured_cards: number;
    number_cameo_cards: number;
}

const hardcodedPokemon: HardcodedPokemon[] = [
    {
        id: 1,
        name: 'Bulbasaur',
        generation: 1,
        number: 1,
        image: 'https://mypokellection.com/Pokedex/001.png',
        height: 7,
        weight: 69,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 4,
        name: 'Charmander',
        generation: 1,
        number: 4,
        image: 'https://mypokellection.com/Pokedex/004.png',
        height: 6,
        weight: 85,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 7,
        name: 'Squirtle',
        generation: 1,
        number: 7,
        image: 'https://mypokellection.com/Pokedex/007.png',
        height: 5,
        weight: 90,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 25,
        name: 'Pikachu',
        generation: 1,
        number: 25,
        image: 'https://mypokellection.com/Pokedex/025.png',
        height: 4,
        weight: 60,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 26,
        name: 'Raichu',
        generation: 1,
        number: 26,
        image: 'https://mypokellection.com/Pokedex/026.png',
        height: 7,
        weight: 130,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 152,
        name: 'Chikorita',
        generation: 2,
        number: 152,
        image: 'https://mypokellection.com/Pokedex/152.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 252,
        name: 'Treecko',
        generation: 3,
        number: 252,
        image: 'https://mypokellection.com/Pokedex/252.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 387,
        name: 'Turtwig',
        generation: 4,
        number: 387,
        image: 'https://mypokellection.com/Pokedex/387.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 495,
        name: 'Snivy',
        generation: 5,
        number: 495,
        image: 'https://mypokellection.com/Pokedex/495.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 650,
        name: 'Chespin',
        generation: 6,
        number: 650,
        image: 'https://mypokellection.com/Pokedex/650.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 722,
        name: 'Rowlet',
        generation: 7,
        number: 722,
        image: 'https://mypokellection.com/Pokedex/722.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 810,
        name: 'Grookey',
        generation: 8,
        number: 810,
        image: 'https://mypokellection.com/Pokedex/810.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
    {
        id: 906,
        name: 'Sprigatito',
        generation: 9,
        number: 906,
        image: 'https://mypokellection.com/Pokedex/906.png',
        height: 6,
        weight: 64,
        number_featured_cards: 1,
        number_cameo_cards: 1,
    },
];

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const MyPokedex: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:789px)');
    const [selectedGen, setSelectedGen] = useState<number>(1); // Default to Gen 1
    const [filterExpanded, setFilterExpanded] = useState(true);

    const userName = useSelector((state: RootState) => state.auth.userName);
    const favoritePokemonId = useSelector((state: RootState) => state.auth.favoritePokemonId);
    const reduxIsAuthenticated = useSelector((state: RootState) => state.auth.authenticated);
    const [favoritePokemon, setFavoritePokemon] = useState(null);

    const { getFavoritePokemon } = useUserFunctions();
    const effectiveUserName = TEST_MODE ? TEST_USER.name : userName || 'Guest';
    const {
        featuredPokemonCards,
        cameoPokemonCards,
        caughtPokemon,
        caughtPokemonIds: cardFunctionsCaughtIds,
        featuredCaughtPokemonCards,
        cameoCaughtPokemonCards,
        pokedexValue,
        cardLoading,
        favoritePokemonCardCount,
        favoritePokemonFeaturedCardCount,
        favoritePokemonCameoCardCount,
        collectedFavoritePokemonCardCount,
        collectedFavoritePokemonFeaturedCardCount,
        collectedFavoritePokemonCameoCardCount,
    } = useCardFunctions(
        effectiveUserName,
        reduxIsAuthenticated,
        favoritePokemonId ? Number(favoritePokemonId) : null
    );

    // Use the TEST_USER when TEST_MODE is true
    const effectiveIsAuthenticated = TEST_MODE ? TEST_USER.isAuthenticated : reduxIsAuthenticated;
    const effectiveFavoritePokemon = TEST_MODE ? TEST_USER.favoritePokemon : favoritePokemon;

    useEffect(() => {
        const fetchFavoritePokemon = async () => {
            if (effectiveIsAuthenticated && favoritePokemonId) {
                try {
                    const favorite = await getFavoritePokemon(Number(favoritePokemonId));
                    setFavoritePokemon(favorite as any);
                } catch (error) {
                    console.error('Error fetching favorite Pokemon:', error);
                }
            } else {
                // console.log('Not authenticated or no favorite Pokemon ID');
            }
        };
        fetchFavoritePokemon();
    }, [effectiveIsAuthenticated, favoritePokemonId, getFavoritePokemon]);

    const { pokedex = [], loading = false } = usePokeDex() || {};

    const filteredPokemon = useMemo(() => {
        if (TEST_MODE) {
            return hardcodedPokemon.filter((pokemon) => pokemon.generation === selectedGen);
        }
        return (pokedex || []).filter((pokemon) => pokemon.generation === selectedGen);
    }, [selectedGen, pokedex, TEST_MODE]);

    const handleGenChange = (event: React.MouseEvent<HTMLElement>, newGen: number | null) => {
        if (newGen !== null) {
            setSelectedGen(newGen);
        }
    };

    const toggleFilterExpanded = () => {
        setFilterExpanded(!filterExpanded);
    };

    const headerData = useMemo(
        () => ({
            userName: effectiveUserName || 'Guest',
            totalPokemon: 1025,
            caughtPokemon,
            caughtFeaturedCards: featuredCaughtPokemonCards,
            caughtCameoCards: cameoCaughtPokemonCards,
            totalFeaturedCards: featuredPokemonCards,
            totalCameoCards: cameoPokemonCards,
            totalMarketValue: pokedexValue,
            cardLoading,
            isAuthenticated: effectiveIsAuthenticated,
        }),
        [
            effectiveUserName,
            caughtPokemon,
            featuredCaughtPokemonCards,
            cameoCaughtPokemonCards,
            featuredPokemonCards,
            cameoPokemonCards,
            pokedexValue,
            cardLoading,
            effectiveIsAuthenticated,
        ]
    );

    const defaultFavoritePokemon = useMemo(
        () => ({
            name: 'Unknown',
            image: '',
            number: 0,
            generation: 0,
            height: 0,
            weight: 0,
            number_featured_cards: 0,
            number_cameo_cards: 0,
        }),
        []
    );

    const favoritePokemonData = useMemo(
        () => ({
            favoritePokemon: effectiveFavoritePokemon || defaultFavoritePokemon,
            favoritePokemonCardCount,
            favoritePokemonFeaturedCardCount,
            favoritePokemonCameoCardCount,
            collectedFavoritePokemonCardCount,
            collectedFavoritePokemonFeaturedCardCount,
            collectedFavoritePokemonCameoCardCount,
        }),
        [
            effectiveFavoritePokemon,
            favoritePokemonCardCount,
            favoritePokemonFeaturedCardCount,
            favoritePokemonCameoCardCount,
            collectedFavoritePokemonCardCount,
            collectedFavoritePokemonFeaturedCardCount,
            collectedFavoritePokemonCameoCardCount,
            defaultFavoritePokemon,
        ]
    );

    const PokedexHeader = isMobile ? MobilePokedexHeader : DesktopPokedexHeader;
    const FavoritePokemon = isMobile ? MobileFavoritePokemon : DesktopFavoritePokemon;
    const PokemonCard = isMobile ? MobilePokemonCard : DesktopPokemonCard;

    const handlePokemonClick = (pokemon: Pokemon) => {
        navigate(`/MyPokedex/${pokemon.id}`, { state: { pokemon } });
    };

    // If either loading state is true, show loading indicator
    if (loading || cardLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div style={{ marginBottom: '5px' }}>
                <PokedexHeader {...headerData} />
            </div>
            {effectiveIsAuthenticated && (
                <div style={{ marginBottom: '5px' }}>
                    <Paper>
                        <Grid item xs={12} sm={4}>
                            <FavoritePokemon {...favoritePokemonData} />
                        </Grid>
                    </Paper>
                </div>
            )}
            <div style={{ marginBottom: '5px' }}>
                <DesktopPokedexFilter
                    selectedGen={selectedGen}
                    handleGenChange={handleGenChange}
                    totalFilteredPokemon={filteredPokemon.length}
                    expanded={filterExpanded}
                    toggleExpanded={toggleFilterExpanded}
                />
            </div>
            <div style={{ marginBottom: '5px' }}>
                <Grid container spacing={2}>
                    {!loading &&
                        filteredPokemon &&
                        filteredPokemon.map((pokemon) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={pokemon.id}>
                                <div onClick={() => handlePokemonClick(pokemon)}>
                                    <PokemonCard
                                        pokemon={pokemon as Pokemon}
                                        hasCaught={(cardFunctionsCaughtIds || []).includes(
                                            pokemon.id
                                        )}
                                    />
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </div>
            <ScrollTop />
        </>
    );
};

export default MyPokedex;
