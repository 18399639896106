import { RouteType } from './config';
import '../css/index.css';

import HomePage from '../pages/home/HomePage';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
import ResetPassword from '../pages/login/resetPassword';
import UserProfile from '../pages/userProfile/userProfile';
import LoginPage from '../pages/login/loginpage';
import MyPokedex from '../pages/myPokedex/MyPokedex';

import { Login, CatchingPokemon } from '@mui/icons-material'; // Add CatchingPokemon icon

import englishAppRoutes from './englishAppRoutes';

import adminRoutes from './adminRoutes';

const defaultRoute: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: 'home',
        tcgState: 'All',
        beta: false,
    },
    {
        path: '/MyPokedex',
        element: <MyPokedex />,
        state: 'MyPokedex',
        tcgState: 'All',
        beta: false,
        sidebarProps: {
            displayText: 'My Pokédex',
            icon: <CatchingPokemon />,
        },
    },
    {
        path: '/login',
        element: <LoginPage />,
        state: 'Login',
        tcgState: 'Admin',
        beta: false,
        sidebarProps: {
            displayText: 'Login/Sign Up',
            icon: <Login />,
        },
    },
    {
        path: '/login',
        element: <LoginPage />,
        state: 'Logout',
        tcgState: 'Admin',
        beta: false,
        sidebarProps: {
            displayText: 'Logout',
            icon: <Login />,
        },
    },
    {
        path: '/u/:userName',
        element: <UserProfile />,
        state: 'UserProfile',
        tcgState: 'All',
        beta: true,
        sidebarProps: {
            displayText: 'User Profile',
        },
    },
];

const privacyPolicyRoute: RouteType[] = [
    {
        path: '/PrivacyPolicy',
        element: <PrivacyPolicy />,
        state: 'PrivacyPolicy',
        tcgState: 'Admin',
        beta: false,
        sidebarProps: {
            displayText: 'PrivacyPolicy',
        },
    },
];

const hiddenPages: RouteType[] = [
    {
        path: '/resetPassword/:token',
        element: <ResetPassword />,
        state: 'ResetPassword',
        tcgState: 'Admin',
        beta: true,
        sidebarProps: {
            displayText: 'Reset Password',
        },
    },
];

const appRoutes: RouteType[] = [
    ...defaultRoute,
    ...adminRoutes,
    ...hiddenPages,
    ...englishAppRoutes,
    ...privacyPolicyRoute,
];

export default appRoutes;
