import React, { useState } from 'react';
import { Paper, Box, Pagination } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CameoCardTemplate from './CameoCardTemplate';

import './CameoCardDisplay.css';
import assets from '../../../../assets';
import { CameoCard } from '../../../../types/admin';

interface CameoCardDisplayProps {
    cards: CameoCard[];
    filterStatus: 'processed' | 'unprocessed';
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (updatedCards: CameoCard[]) => void;
}

const CARDS_PER_PAGE = 21;

const CameoCardDisplay: React.FC<CameoCardDisplayProps> = ({
    cards,
    filterStatus,
    page,
    setPage,
    onCardsUpdate,
}) => {
    const [selectedCard, setSelectedCard] = useState<CameoCard | null>(null);

    const filteredCards = cards.filter((card) => {
        if (filterStatus === 'processed') {
            return card.isCameoComplete;
        } else {
            return !card.isCameoComplete;
        }
    });

    const totalPages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);
    const startIndex = (page - 1) * CARDS_PER_PAGE;
    const paginatedCards = filteredCards.slice(startIndex, startIndex + CARDS_PER_PAGE);

    const handleCardClick = (card: CameoCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handleSubmitSuccess = () => {
        // Remove the cards update logic from here since we only want it on completion
    };

    const handleCompleteSuccess = () => {
        const updatedCards = cards.map((card) =>
            card.id === selectedCard?.id
                ? { ...card, isCameoComplete: !card.isCameoComplete }
                : card
        );
        onCardsUpdate(updatedCards);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="desktop-set-cards-container">
                {paginatedCards.map((card) => (
                    <div key={card.id} className="desk-card-container">
                        <Paper
                            elevation={4}
                            sx={{
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                    transform: 'translateY(-4px)',
                                },
                            }}
                        >
                            <div className="desktop-card-box">
                                <p className="mobile-centered-text">{card.name}</p>
                                <LazyLoadImage
                                    className="card-image"
                                    src={card.imagelocation}
                                    alt={card.name}
                                    effect="blur"
                                    placeholderSrc={assets.site.images.backupImage}
                                    width="100%"
                                    height="auto"
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleCardClick(card)}
                                />
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 3,
                        mb: 3,
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </Box>
            )}

            {selectedCard && (
                <CameoCardTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                    onCompleteSuccess={handleCompleteSuccess}
                />
            )}
        </>
    );
};

export default CameoCardDisplay;
