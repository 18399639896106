import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';

interface CardCategoryHeaderProps {
    totalCards: number;
    processedCards: number;
    loading?: boolean;
}

const CardCategoryHeader: React.FC<CardCategoryHeaderProps> = ({
    totalCards,
    processedCards,
    loading = false,
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Card Category Manager</Typography>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <Typography variant="subtitle1">
                        Cards Processed: {processedCards}/{totalCards}
                    </Typography>
                )}
            </Box>
        </Paper>
    );
};

export default CardCategoryHeader;
