import { useState, useEffect, useCallback } from 'react';

export interface Pokemon {
    id: number;
    number: number;
    name: string;
    image: string;
    generation: number;
    height: number | null;
    weight: number | null;
    number_featured_cards: number;
    number_cameo_cards: number;
}

const CACHE_KEY = 'pokedexCache';
//const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
//const CACHE_EXPIRATION = 60 * 1000; // 1 minute
const CACHE_EXPIRATION = 0; // No Cache

export const usePokeDex = (pageSize = 50) => {
    const [pokedex, setPokedex] = useState<Pokemon[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const getCachedData = () => {
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (Date.now() - timestamp < CACHE_EXPIRATION) {
                return data;
            }
        }
        return null;
    };

    const setCachedData = (data: { pokedex: Pokemon[] }) => {
        localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
                data,
                timestamp: Date.now(),
            })
        );
    };

    const fetchPokedex = useCallback(async () => {
        try {
            setLoading(true);

            // Check cache first
            if (page === 1) {
                const cachedData = getCachedData();
                if (cachedData) {
                    setPokedex(cachedData.pokedex);
                    setLoading(false);
                    return;
                }
            }

            const response = await fetch(`/api/getPokedex?page=${page}&pageSize=${pageSize}`);
            if (!response.ok) {
                throw new Error('Failed to fetch Pokédex');
            }
            const data = await response.json();

            // Check if data is an array
            if (!Array.isArray(data)) {
                console.error('Received invalid pokedex data:', data);
                throw new Error('Invalid pokedex data received');
            }

            const newPokedex = [...pokedex, ...data];
            setPokedex(newPokedex);
            setHasMore(data.length === pageSize);
            setPage((prevPage) => prevPage + 1);

            // Update cache if it's the first page
            if (page === 1) {
                setCachedData({
                    pokedex: newPokedex,
                });
            }
        } catch (err) {
            console.error('Error in fetchPokedex:', err);
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    }, [page, pageSize, pokedex]);

    useEffect(() => {
        fetchPokedex();
    }, []); // Empty dependency array to run only once on mount

    const refreshPokedex = useCallback(() => {
        setPokedex([]);
        setPage(1);
        setHasMore(true);
        localStorage.removeItem(CACHE_KEY);
        fetchPokedex();
    }, [fetchPokedex]);

    return {
        pokedex,
        loading,
        error,
        hasMore,
        fetchPokedex,
        refreshPokedex,
    };
};
