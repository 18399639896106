import { Navigate } from 'react-router-dom';
import { useUserFunctions } from '../hooks/useUserFunctions';

interface AdminRouteProps {
    children: React.ReactNode;
}

const AdminRoute = ({ children }: AdminRouteProps) => {
    const { isAdmin, isAdminLoading } = useUserFunctions();

    if (isAdminLoading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (!isAdmin) {
        return <Navigate to="/" replace />; // Redirect non-admins to home
    }

    return <>{children}</>;
};

export default AdminRoute;
