import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Card,
    Divider,
    Button,
    FormControl,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import '../../../../css/popup.css';
import assets from '../../../../assets';
import { PokemonSelector } from '../../../../components/PokemonSelector';

interface CameoCard {
    id: number;
    name: string;
    imagelocation: string;
    imageLocation2x: string | null;
    setName: string;
    seriesName: string;
    isCameoComplete: boolean;
    updatedAt: string;
}

interface Pokemon {
    id: number;
    name: string;
    picURL: string;
    number: string;
}

interface Props {
    cardInfo: CameoCard;
    onClose: () => void;
    onSubmitSuccess?: () => void;
    onCompleteSuccess?: () => void;
}

const CameoCardTemplate: React.FC<Props> = ({
    cardInfo,
    onClose,
    onSubmitSuccess,
    onCompleteSuccess,
}) => {
    const [selectedPokemonId, setSelectedPokemonId] = useState<number | null>(null);
    const [isCameoComplete] = useState<boolean>(cardInfo.isCameoComplete || false);
    const {
        updateCardCameo,
        loading,
        getCurrentCameoPokemon,
        completeCameoCard,
        removeCameoPokemon,
    } = useAdminFunctions();
    const containerRef = useRef<HTMLDivElement>(null);
    const [cameoPokemon, setCameoPokemon] = useState<Pokemon[]>([]);

    const handleSubmit = async () => {
        if (!selectedPokemonId) return;

        const success = await updateCardCameo(cardInfo.id, selectedPokemonId);
        if (success) {
            const updatedPokemon = await getCurrentCameoPokemon(cardInfo.id);
            setCameoPokemon(updatedPokemon);
            setSelectedPokemonId(null);
            if (onSubmitSuccess) {
                await onSubmitSuccess();
            }
        }
    };

    const handleComplete = async () => {
        const success = await completeCameoCard(cardInfo.id, isCameoComplete);
        if (success) {
            if (onCompleteSuccess) {
                onCompleteSuccess();
            }
            onClose();
        }
    };

    const handleRemovePokemon = async (pokemonId: number) => {
        const success = await removeCameoPokemon(cardInfo.id, pokemonId);
        if (success) {
            // Refresh the Cameo Pokemon list
            const updatedPokemon = await getCurrentCameoPokemon(cardInfo.id);
            setCameoPokemon(updatedPokemon);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            // Check if click is on Autocomplete or its dropdown
            const isAutocompleteClick = target.closest('.MuiAutocomplete-root') !== null;
            const isAutocompletePopup = target.closest('.MuiAutocomplete-popper') !== null;
            const isCheckboxClick = target.closest('.MuiCheckbox-root') !== null;

            if (isAutocompleteClick || isAutocompletePopup || isCheckboxClick) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        const fetchCameoPokemon = async () => {
            const pokemon = await getCurrentCameoPokemon(cardInfo.id);
            setCameoPokemon(pokemon);
        };

        fetchCameoPokemon();
    }, [cardInfo.id, getCurrentCameoPokemon]);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                width: '100%',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <LazyLoadImage
                                src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                                alt={cardInfo.name}
                                effect="blur"
                                placeholderSrc={assets.site.images.backupImage}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '400px',
                                    objectFit: 'contain',
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: 1,
                                    p: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                }}
                            >
                                <Typography variant="h4" component="h3">
                                    {cardInfo.name}
                                </Typography>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        display: 'flex',
                                        gap: 2,
                                        '& > div': { flex: 1 },
                                    }}
                                >
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Series Name
                                        </Typography>
                                        <Typography variant="body1">
                                            {cardInfo.seriesName}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Set Name
                                        </Typography>
                                        <Typography variant="body1">{cardInfo.setName}</Typography>
                                    </div>
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        width: '100%',
                                    }}
                                >
                                    {cameoPokemon.length > 0 && (
                                        <>
                                            <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                Currently Cameo Pokemon
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 2,
                                                    mb: 2,
                                                }}
                                            >
                                                {cameoPokemon.map((pokemon) => (
                                                    <Paper
                                                        key={pokemon.id}
                                                        elevation={3}
                                                        sx={{
                                                            position: 'relative',
                                                            width: 200,
                                                            p: 1,
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            transition:
                                                                'box-shadow 0.3s, transform 0.3s',
                                                            '&:hover': {
                                                                boxShadow:
                                                                    '0 8px 16px 0 rgba(0,0,0,0.2)',
                                                                transform: 'translateY(-4px)',
                                                            },
                                                        }}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleRemovePokemon(pokemon.id)
                                                            }
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 4,
                                                                top: 4,
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <LazyLoadImage
                                                                src={pokemon.picURL}
                                                                alt={pokemon.name}
                                                                effect="blur"
                                                                style={{
                                                                    width: '120px',
                                                                    height: '120px',
                                                                    objectFit: 'contain',
                                                                }}
                                                            />
                                                            <Typography variant="subtitle1">
                                                                {pokemon.name}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                #{pokemon.number}
                                                            </Typography>
                                                        </Box>
                                                    </Paper>
                                                ))}
                                            </Box>
                                            <Divider sx={{ mb: 2 }} />
                                        </>
                                    )}

                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        What Pokemon is Featured in this Card?
                                    </Typography>
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <PokemonSelector
                                            value={selectedPokemonId}
                                            onChange={(newValue) => setSelectedPokemonId(newValue)}
                                        />
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        disabled={!selectedPokemonId || loading}
                                        color={cardInfo.isCameoComplete ? 'error' : 'primary'}
                                        sx={{ mb: 1 }}
                                    >
                                        {cardInfo.isCameoComplete
                                            ? 'Update Card'
                                            : 'Add Cameo Pokemon'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleComplete}
                                        disabled={loading || cameoPokemon.length === 0}
                                        color="success"
                                        sx={{ mb: 1 }}
                                    >
                                        Complete Card
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CameoCardTemplate;
