import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import assets from '../../assets';

interface PokedexHeaderProps {
    userName: string;
    totalPokemon: number;
    caughtPokemon: number;
    caughtFeaturedCards: number;
    caughtCameoCards: number;
    totalFeaturedCards: number;
    totalCameoCards: number;
    totalMarketValue: number;
    isAuthenticated: boolean;
}

const MobilePokedexHeader: React.FC<PokedexHeaderProps> = ({
    userName,
    totalPokemon,
    caughtPokemon,
    caughtFeaturedCards,
    caughtCameoCards,
    totalFeaturedCards,
    totalCameoCards,
    totalMarketValue,
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
                {userName ? `${userName}` : 'Guest'}'s Pokédex
            </Typography>
            <Box mt={2}>
                <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                    <img
                        loading="lazy"
                        className="mobile-pokeball"
                        src={assets.site.images.pokeballCaught}
                        alt="Pokéball"
                        style={{ marginRight: '8px', width: '20px', height: '20px' }}
                    />
                    <Typography variant="body2" align="center">
                        Pokémon: {caughtPokemon}/{totalPokemon}
                    </Typography>
                </Box>
                <Typography variant="body2" align="center" mb={1}>
                    Featured Cards: {caughtFeaturedCards}/{totalFeaturedCards}
                </Typography>
                <Typography variant="body2" align="center" mb={1}>
                    Cameo Cards: {caughtCameoCards}/{totalCameoCards}
                </Typography>
                <Typography variant="body2" align="center">
                    Pokédex Value: ${totalMarketValue.toFixed(2)}
                </Typography>
            </Box>
        </Paper>
    );
};

export default MobilePokedexHeader;
