import React, { useState } from 'react';
import { Paper, Box, Pagination } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CardImageTemplate from './CardImageTemplate';

import './CardImageCardDisplay.css';
import assets from '../../../../assets';
import { PokemonCard } from '../../../../api/pokemonSetApi';

interface CardImageDisplayProps {
    cards: PokemonCard[];
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (updatedCards: PokemonCard[]) => void;
}

const CARDS_PER_PAGE = 21;

const CardImageDisplay: React.FC<CardImageDisplayProps> = ({
    cards,
    page,
    setPage,
    onCardsUpdate,
}) => {
    const [selectedCard, setSelectedCard] = useState<PokemonCard | null>(null);

    const totalPages = Math.ceil(cards.length / CARDS_PER_PAGE);
    const startIndex = (page - 1) * CARDS_PER_PAGE;
    const paginatedCards = cards.slice(startIndex, startIndex + CARDS_PER_PAGE);

    const handleCardClick = (card: PokemonCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handleSubmitSuccess = () => {
        // Remove the cards update logic from here since we only want it on completion
    };

    const handleCompleteSuccess = () => {
        // const updatedCards = cards.map(card =>
        //     card.id === selectedCard?.id
        //         ? { ...card, isFeaturedComplete: !card.isFeaturedComplete }
        //         : card
        // );
        // onCardsUpdate(updatedCards);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="desktop-set-cards-container">
                {paginatedCards.map((card) => (
                    <div key={card.cardsetindex} className="desk-card-container">
                        <Paper
                            elevation={4}
                            sx={{
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                    transform: 'translateY(-4px)',
                                },
                            }}
                        >
                            <div className="desktop-card-box">
                                <p className="mobile-centered-text">{card.name}</p>
                                <LazyLoadImage
                                    className="card-image"
                                    src={card.imagelocation || assets.site.images.backupImage}
                                    alt={card.name}
                                    effect="blur"
                                    placeholderSrc={assets.site.images.backupImage}
                                    width="100%"
                                    height="auto"
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleCardClick(card)}
                                />
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 3,
                        mb: 3,
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </Box>
            )}

            {selectedCard && (
                <CardImageTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                    onCompleteSuccess={handleCompleteSuccess}
                />
            )}
        </>
    );
};

export default CardImageDisplay;
