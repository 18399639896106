import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ImageIcon from '@mui/icons-material/Image';

const AdminToolsPage: React.FC = () => {
    const tools = [
        {
            name: 'Card Category Manager',
            path: '/admin/tools/CardCategoryManager',
            bgcolor: '#FF6B6B', // coral red
            hoverColor: '#FF8787',
            icon: <CategoryIcon sx={{ fontSize: 40, color: 'white' }} />,
        },
        {
            name: 'Featured Pokemon Manager',
            path: '/admin/tools/FeaturedCardManager',
            bgcolor: '#4ECDC4', // turquoise
            hoverColor: '#6EE7DE',
            icon: <StarIcon sx={{ fontSize: 40, color: 'white' }} />,
        },
        {
            name: 'Cameo Pokemon Manager',
            path: '/admin/tools/CameoCardManager',
            bgcolor: '#95A5A6', // purple
            hoverColor: '#B5C5C6',
            icon: <GroupWorkIcon sx={{ fontSize: 40, color: 'white' }} />,
        },
        {
            name: 'Card Image Manager',
            path: '/admin/tools/CardImageManager',
            bgcolor: '#4FC1E9', // teal
            hoverColor: '#66C5E9',
            icon: <ImageIcon sx={{ fontSize: 40, color: 'white' }} />,
        },
    ];

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Admin Tools
            </Typography>
            <Grid container spacing={3}>
                {tools.map((tool) => (
                    <Grid item xs={12} sm={6} md={4} key={tool.name}>
                        <Paper
                            component={Link}
                            to={tool.path}
                            sx={{
                                bgcolor: tool.bgcolor,
                                height: 200,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textDecoration: 'none',
                                transition: 'all 0.3s ease',
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: tool.hoverColor,
                                    transform: 'translateY(-8px)',
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            {tool.icon}
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'white',
                                    mt: 2,
                                    textAlign: 'center',
                                    px: 2,
                                }}
                            >
                                {tool.name}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AdminToolsPage;
