import { Drawer, List, Stack, Toolbar } from '@mui/material';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import appRoutes from '../../routes/appRoutes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';

const Sidebar = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { authenticated, userName, favoritePokemonImage } = auth;
    const TCGState = useSelector((state: RootState) => state.TCGState);
    const authenticatedStorage = localStorage.getItem('authenticated');
    //const userName = 'MyPokellection'

    function filterRoutes(routes: RouteType[]) {
        const adminKey = localStorage.getItem('adminKey');

        return routes.filter((route) => {
            // Filter out routes that have the beta property set to true
            if (route.beta) {
                return false;
            }

            // Hide admin routes if no admin key
            if (route.path?.startsWith('/admin') && !adminKey) {
                return false;
            }

            // Recursively filter the child routes of this route
            if (route.child) {
                route.child = filterRoutes(route.child);
            }

            if (authenticated || authenticatedStorage === 'true') {
                return (
                    route.sidebarProps &&
                    (route.tcgState === 'All' ||
                        route.tcgState === TCGState ||
                        route.state === 'Logout')
                );
            } else {
                return (
                    route.sidebarProps &&
                    (route.tcgState === 'All' ||
                        route.tcgState === TCGState ||
                        route.state === 'Login')
                );
            }
        });
    }

    let filteredRoutes = filterRoutes(appRoutes);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
        >
            <List disablePadding>
                <Toolbar>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                        <Link to="/">
                            <img
                                style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                src={assets.site.images.logo}
                                alt=""
                            ></img>
                        </Link>
                    </Stack>
                </Toolbar>

                <div className="sidebar-userProfile">
                    {authenticated ? (
                        <>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <Avatar
                                    alt={userName ?? 'Guest'}
                                    src={favoritePokemonImage || assets.pokedex.pokemon.PK123}
                                    key={favoritePokemonImage}
                                />
                            </Link>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <p>{userName ?? 'Guest'}</p>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Avatar alt="Guest" src={assets.pokedex.pokemon.PK123} />
                            <p>Guest</p>
                        </>
                    )}
                </div>

                {filteredRoutes.map((route, index) =>
                    route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            <SidebarItem item={route} key={index} />
                        )
                    ) : null
                )}
            </List>
            <div>
                <Link className="privacypolicy" to="/PrivacyPolicy">
                    Privacy Policy
                </Link>
            </div>
            <div>
                <p className="footer-text">
                    MyPokellection is not affiliated with, sponsored or endorsed by, or in any way
                    associated with Pokemon or The Pokemon Company International Inc
                </p>
            </div>
        </Drawer>
    );
};

export default Sidebar;
