import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    collectedFirstEditionSet: 0,
    collectedShadowlessSet: 0,
    collectedUnlimitedSet: 0,
    collectedFourthPrint: 0,
    collectedCards: 0,
};

const collectedSetsSlice = createSlice({
    name: 'collectedSets',
    initialState,
    reducers: {
        setCollectedFirstEditionSet: (state, action) => {
            state.collectedFirstEditionSet = action.payload;
        },
        setCollectedShadowlessSet: (state, action) => {
            state.collectedShadowlessSet = action.payload;
        },
        setCollectedUnlimitedSet: (state, action) => {
            state.collectedUnlimitedSet = action.payload;
        },
        setCollectedFourthPrint: (state, action) => {
            state.collectedFourthPrint = action.payload;
        },
        setCollectedCards: (state, action) => {
            state.collectedCards = action.payload;
        },
    },
});

const { actions, reducer } = collectedSetsSlice;

export const {
    setCollectedFirstEditionSet,
    setCollectedShadowlessSet,
    setCollectedUnlimitedSet,
    setCollectedFourthPrint,
    setCollectedCards,
} = actions;

export default reducer;
