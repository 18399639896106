import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    authenticated: boolean;
    userName: string | null;
    favoritePokemonImage: string | null;
    favoritePokemonId: number | null;
    token: string | null;
}

const getInitialState = (): AuthState => {
    const storedState = localStorage.getItem('authState');
    if (storedState) {
        return JSON.parse(storedState);
    }
    return {
        authenticated: false,
        userName: null,
        favoritePokemonImage: null,
        favoritePokemonId: null,
        token: null,
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        setUserName: (state, action: PayloadAction<string | null>) => {
            state.userName = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        setFavoritePokemonImage: (state, action: PayloadAction<string | null>) => {
            state.favoritePokemonImage = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        setFavoritePokemonId: (state, action: PayloadAction<number | null>) => {
            state.favoritePokemonId = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        setToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        logout: (state) => {
            state.authenticated = false;
            state.userName = null;
            state.favoritePokemonImage = null;
            state.token = null;
            localStorage.removeItem('authState');
        },
    },
});

export const {
    setAuthenticated,
    setUserName,
    setFavoritePokemonImage,
    setFavoritePokemonId,
    setToken,
    logout,
} = authSlice.actions;
export default authSlice.reducer;
