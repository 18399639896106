import React from 'react';
import { Paper, CardContent, Typography, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Pokemon } from '../../hooks/usePokeDex';
import assets from '../../assets';

interface DesktopPokemonCardProps {
    pokemon: Pokemon;
    hasCaught: boolean;
}

const DesktopPokemonCard: React.FC<DesktopPokemonCardProps> = ({ pokemon, hasCaught }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                p: 1,
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'box-shadow 0.3s, transform 0.3s',
                '&:hover': {
                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                    transform: 'translateY(-4px)',
                },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
                <LazyLoadImage
                    src={pokemon.image || `https://mypokellection.com/Pokedex/${pokemon.id}.png`}
                    alt={pokemon.name}
                    effect="blur"
                    width={96}
                    height={96}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            </Box>
            <CardContent
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h6" component="div" align="center">
                    {pokemon.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                    #{pokemon.id.toString().padStart(3, '0')}
                </Typography>
            </CardContent>
            <Typography variant="body2" align="center">
                Feature Cards: {pokemon.number_featured_cards}
            </Typography>
            <Typography variant="body2" align="center">
                Cameo Cards: {pokemon.number_cameo_cards}
            </Typography>
            <Box mt={1} display="flex" justifyContent="flex-end">
                <LazyLoadImage
                    src={
                        hasCaught ? assets.site.images.pokeballCaught : assets.site.images.pokeball
                    }
                    alt={hasCaught ? 'Caught' : 'Not caught'}
                    effect="blur"
                    width={24}
                    height={24}
                />
            </Box>
        </Paper>
    );
};

export default DesktopPokemonCard;
