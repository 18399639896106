import React, { useState, useEffect, useCallback } from 'react';
import { Box, useScrollTrigger, Zoom, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CameoCardHeader from './CameoCardHeader';
import CameoCardFilter, { Filters } from './CameoCardFilter';
import CameoCardDisplay from './CameoCardDisplay';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import { CameoCard } from '../../../../types/admin';
import LoadingSpinner from '../../../../components/LoadingSpinner';

function ScrollTop() {
    const trigger = useScrollTrigger({
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
            >
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    );
}

const CameoCardManager: React.FC = () => {
    const { getCameoCardStats, getCameoPokemonCards } = useAdminFunctions();
    const [totalCards, setTotalCards] = useState(0);
    const [cameoCards, setCameoCards] = useState(0);
    const [cards, setCards] = useState<CameoCard[]>([]);
    const [filters, setFilters] = useState<Filters>({
        status: 'unprocessed',
    });
    const [page, setPage] = useState(1);
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const fetchStats = useCallback(async () => {
        const stats = await getCameoCardStats();
        setTotalCards(stats.totalCards);
        setCameoCards(stats.cameoCards);
    }, [getCameoCardStats]);

    const fetchCards = useCallback(async () => {
        const fetchedCards = await getCameoPokemonCards();
        setCards(fetchedCards);
    }, [getCameoPokemonCards]);

    useEffect(() => {
        const fetchInitialData = async () => {
            await Promise.all([fetchStats(), fetchCards()]);
            setIsInitialLoading(false);
        };

        fetchInitialData();
    }, [fetchStats, fetchCards]);

    const handleFiltersChange = (newFilters: Filters) => {
        setFilters(newFilters);
        setPage(1);
    };

    const handleCardsUpdate = (updatedCards: CameoCard[]) => {
        setCards(updatedCards);
        setCameoCards((prev) => (filters.status === 'unprocessed' ? prev + 1 : prev - 1));
        setTotalCards((prev) => prev);
    };

    if (isInitialLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <CameoCardHeader totalCards={totalCards} cameoCards={cameoCards} />
                <CameoCardFilter filters={filters} setFilters={handleFiltersChange} />
                <CameoCardDisplay
                    cards={cards}
                    filterStatus={filters.status}
                    page={page}
                    setPage={setPage}
                    onCardsUpdate={handleCardsUpdate}
                />
            </Box>
            <ScrollTop />
        </>
    );
};

export default CameoCardManager;
