import React, { useState } from 'react';
import { Typography, Avatar, Box, Paper, Grid } from '@mui/material';

interface FavoritePokemonProps {
    favoritePokemon: {
        name: string;
        image: string;
        number: number;
        generation: number;
        height: number;
        weight: number;
        number_featured_cards: number;
        number_cameo_cards: number;
    };
    favoritePokemonCardCount: number;
    favoritePokemonFeaturedCardCount: number;
    favoritePokemonCameoCardCount: number;
    collectedFavoritePokemonCardCount: number;
    collectedFavoritePokemonFeaturedCardCount: number;
    collectedFavoritePokemonCameoCardCount: number;
}

const MobileFavoritePokemon: React.FC<FavoritePokemonProps> = (props) => {
    const [expanded, setExpanded] = useState(true);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Paper elevation={4} style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Favorite Pokémon
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Generation: {props.favoritePokemon.generation} |{' '}
                            {props.favoritePokemon.name} #{props.favoritePokemon.number}
                        </Typography>
                    </Box>
                    <Avatar
                        src={props.favoritePokemon.image}
                        alt={props.favoritePokemon.name}
                        sx={{ width: 80, height: 80 }}
                    />
                </Box>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={4}>
                        <Typography variant="body2">Total Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonCardCount}/
                            {props.favoritePokemonCardCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2">Featured Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonFeaturedCardCount}/
                            {props.favoritePokemonFeaturedCardCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2">Cameo Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonCameoCardCount}/
                            {props.favoritePokemonCameoCardCount}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default MobileFavoritePokemon;
