export interface Type {
    id: number;
    name: string;
    iconUrl: string;
    displayIndex: number; // Add this line
}

export const defaultTypes: Type[] = [
    {
        id: 3,
        name: 'Fire',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/fire.png',
        displayIndex: 1,
    },
    {
        id: 2,
        name: 'Grass',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/grass.png',
        displayIndex: 2,
    },
    {
        id: 5,
        name: 'Lightning',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/lightning.png',
        displayIndex: 3,
    },
    {
        id: 8,
        name: 'Psychic',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/psychic.png',
        displayIndex: 4,
    },
    {
        id: 7,
        name: 'Fighting',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/fighting.png',
        displayIndex: 5,
    },
    {
        id: 1,
        name: 'Water',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/water.png',
        displayIndex: 6,
    },
    {
        id: 6,
        name: 'Dark',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/dark.png',
        displayIndex: 7,
    },
    {
        id: 16,
        name: 'Metal',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/metal.png',
        displayIndex: 8,
    },
    {
        id: 10,
        name: 'Dragon',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/dragon.png',
        displayIndex: 9,
    },
    {
        id: 33,
        name: 'Fairy',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/fairy.png',
        displayIndex: 10,
    },
    {
        id: 4,
        name: 'Colorless',
        iconUrl: 'https://mypokellection.com/ImageAssets/TypeIcons/colorless.png',
        displayIndex: 99,
    },
    {
        id: 0,
        name: 'Unknown',
        iconUrl: 'https://mypokellection.com/ImageAssets/icons/question-mark.png',
        displayIndex: 100,
    },
];
