import assets from '../assets';

let cards = [
    {
        id: 1,
        cardsetindex: 1,
        number: 1,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 1,
        type_id: 0,
    },
    {
        id: 2,
        cardsetindex: 2,
        number: 2,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 2,
        type_id: 2,
    },
    {
        id: 3,
        cardsetindex: 3,
        number: 3,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 3,
        type_id: 3,
    },
    {
        id: 4,
        cardsetindex: 4,
        number: 4,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 4,
        type_id: 4,
    },
    {
        id: 5,
        cardsetindex: 5,
        number: 5,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 5,
        type_id: 5,
    },
    {
        id: 6,
        cardsetindex: 6,
        number: 6,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 6,
        type_id: 6,
    },
    {
        id: 7,
        cardsetindex: 7,
        number: 7,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 7,
        type_id: 7,
    },
    {
        id: 8,
        cardsetindex: 8,
        number: 8,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 8,
        type_id: 8,
    },
    {
        id: 9,
        cardsetindex: 9,
        number: 9,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 9,
        type_id: 9,
    },
    {
        id: 10,
        cardsetindex: 10,
        number: 10,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 10,
        type_id: 10,
    },
    {
        id: 11,
        cardsetindex: 11,
        number: 11,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 11,
        type_id: 11,
    },
    {
        id: 12,
        cardsetindex: 12,
        number: 12,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 12,
        type_id: 12,
    },
    {
        id: 13,
        cardsetindex: 13,
        number: 14,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 13,
        type_id: 13,
    },
    {
        id: 14,
        cardsetindex: 14,
        number: 14,
        name: 'Loading...',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
        isReverseHolo: false,
        isPartOfSubSet: false,
        editionId: 3,
        market_price: 0.0,
        rarityId: 1,
        type_id: 0,
    },
];

export default cards;
