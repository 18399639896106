import { createReducer } from '@reduxjs/toolkit';

const initialAuthenticatedState = false;

const loginPageAuthenticatedReducer = createReducer(initialAuthenticatedState, {
    SET_AUTHENTICATED: (state, action) => {
        return action.payload;
    },
});

export default loginPageAuthenticatedReducer;
