import { RouteType } from './config';
import AdminPageLayout from '../pages/admin/AdminPageLayout';
import AdminPageIndex from '../pages/admin/AdminPageIndex';
import AdminToolsPage from '../pages/admin/tools/AdminToolsPage';
import AdminDashboard from '../pages/admin/dashboard/AdminDashboard';
import CardCategoryManager from '../pages/admin/tools/CardCategoryManager/CardCategoryManager';
import FeaturedCardManager from '../pages/admin/tools/FeaturedCardManager/FeaturedCardManager';
import CameoCardManager from '../pages/admin/tools/CameoCardManager/CameoCardManager';
import CardImageManager from '../pages/admin/tools/CardImageManager/CardImageManager';
import { AdminPanelSettings, Dashboard } from '@mui/icons-material';
import AdminRoute from './AdminRoute';

const adminRoutes: RouteType[] = [
    {
        path: '/admin',
        element: (
            <AdminRoute>
                <AdminPageLayout />
            </AdminRoute>
        ),
        state: 'Admin',
        tcgState: 'All',
        beta: false,
        sidebarProps: {
            displayText: 'Admin',
        },
        child: [
            {
                index: true,
                element: (
                    <AdminRoute>
                        <AdminPageIndex />
                    </AdminRoute>
                ),
                state: 'Admin.index',
                tcgState: 'All',
                beta: false,
            },
            {
                path: '/admin/dashboard',
                element: (
                    <AdminRoute>
                        <AdminDashboard />
                    </AdminRoute>
                ),
                state: 'Admin.dashboard',
                tcgState: 'All',
                beta: false,
                sidebarProps: {
                    displayText: 'Dashboard',
                    icon: <Dashboard />,
                },
            },
            {
                path: '/admin/tools',
                element: (
                    <AdminRoute>
                        <AdminToolsPage />
                    </AdminRoute>
                ),
                state: 'Admin.tools',
                tcgState: 'All',
                beta: false,
                sidebarProps: {
                    displayText: 'Admin Tools',
                    icon: <AdminPanelSettings />,
                },
            },
            // Hidden routes - accessible through AdminToolsPage but not shown in sidebar
            {
                path: '/admin/tools/CardCategoryManager',
                element: (
                    <AdminRoute>
                        <CardCategoryManager />
                    </AdminRoute>
                ),
                state: 'Admin.tools',
                tcgState: 'All',
                beta: false,
            },
            {
                path: '/admin/tools/FeaturedCardManager',
                element: (
                    <AdminRoute>
                        <FeaturedCardManager />
                    </AdminRoute>
                ),
                state: 'Admin.tools',
                tcgState: 'All',
                beta: false,
            },
            {
                path: '/admin/tools/CameoCardManager',
                element: (
                    <AdminRoute>
                        <CameoCardManager />
                    </AdminRoute>
                ),
                state: 'Admin.tools',
                tcgState: 'All',
                beta: false,
            },
            {
                path: '/admin/tools/CardImageManager',
                element: (
                    <AdminRoute>
                        <CardImageManager />
                    </AdminRoute>
                ),
                state: 'Admin.tools',
                tcgState: 'All',
                beta: false,
            },
        ],
    },
];

export default adminRoutes;
