import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { routes } from './routes';
import ReactGA from 'react-ga4';
import { AuthProvider } from './context/AuthContext';
import MyPokedex from './pages/myPokedex/MyPokedex';
import PokemonDetail from './pages/myPokedex/PokemonDetail';

ReactGA.initialize('G-677WMVXWLS');

const App: React.FC = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                        {routes}
                        <Route path="MyPokedex" element={<MyPokedex />} />
                        <Route path="MyPokedex/:id" element={<PokemonDetail />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;
