import React, { createContext, useContext, useState, useEffect } from 'react';

interface AuthContextType {
    authParams: any;
    setAuthParams: (params: any) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
    adminKey: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [authParams, setAuthParams] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [adminKey, setAdminKey] = useState<string | null>(localStorage.getItem('adminKey'));

    useEffect(() => {
        // Check local storage for existing auth data on component mount
        const storedAuthParams = localStorage.getItem('authParams');
        const adminKey = localStorage.getItem('adminKey');

        if (storedAuthParams) {
            const parsedParams = JSON.parse(storedAuthParams);
            // Include adminKey in the initial authParams if it exists
            const updatedParams = adminKey ? { ...parsedParams, adminKey } : parsedParams;

            setAuthParams(updatedParams);
            setIsAuthenticated(true);
        }
    }, []);

    const updateAuthParams = (params: any) => {
        const adminKey = localStorage.getItem('adminKey');
        const updatedParams = adminKey ? { ...params, adminKey } : params;

        setAuthParams(updatedParams);
        localStorage.setItem('authParams', JSON.stringify(updatedParams));
        setIsAuthenticated(true);
    };

    return (
        <AuthContext.Provider
            value={{
                authParams,
                setAuthParams: updateAuthParams,
                isAuthenticated,
                setIsAuthenticated,
                adminKey,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
