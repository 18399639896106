import { ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import colorConfigs from '../../configs/colorConfigs';
import { RootState } from '../../redux/store';
import { RouteType } from '../../routes/config';

type Props = {
    item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
    const { appState } = useSelector((state: RootState) => state.appState);

    return item.sidebarProps && item.path ? (
        <ListItemButton
            component={Link}
            to={item.path}
            sx={{
                '&:hover': {
                    backgroundColor: colorConfigs.sidebar.hoverBg,
                },
                backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : 'unset',
                paddingX: '24px',
            }}
        >
            <ListItemIcon
                sx={{
                    color: colorConfigs.sidebar.color,
                }}
            >
                {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>

            <Typography
                sx={{
                    color: appState === item.state ? '#fff400' : 'inherit',
                }}
            >
                {item.sidebarProps.displayText}
            </Typography>
        </ListItemButton>
    ) : null;
};

export default SidebarItem;
