import React, { useState } from 'react';
import { Divider, Paper } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CardTemplate from '../English/Template/CardTemplate';

interface NEWMobileCardDisplayProps {
    cardsToDisplay: any[];
    assets: any;
    showPriceData: boolean;
    changeCardSelectedStatus: (card: any) => void;
    changeCardCollectionStatus: (cards: any[], card: any) => void;
}

const NEWMobileCardDisplay: React.FC<NEWMobileCardDisplayProps> = ({
    cardsToDisplay = [],
    assets,
    showPriceData,
    changeCardSelectedStatus,
    changeCardCollectionStatus,
}) => {
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = (card: any) => {
        setSelectedCard(card);
        setIsModalOpen(true);
        changeCardSelectedStatus(card);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCard(null);
    };

    if (!cardsToDisplay || cardsToDisplay.length === 0) {
        return <Divider>No cards to display</Divider>;
    }

    return (
        <>
            <div className="mobile-set-cards-container">
                {cardsToDisplay &&
                    cardsToDisplay.map((card) => (
                        <div key={card.id} className="mobile-card-container">
                            <Paper
                                elevation={4}
                                sx={{
                                    '&:hover': {
                                        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                        transform: 'translateY(-4px)',
                                    },
                                }}
                            >
                                <div className="mobile-card-box">
                                    <p className="mobile-centered-text">{card.name}</p>
                                    <LazyLoadImage
                                        className="card-image"
                                        src={card.imagelocation || assets.site.images.backupImage}
                                        alt={card.name}
                                        effect="blur"
                                        width="100%"
                                        height="auto"
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleCardClick(card)}
                                    />
                                    <div className="card-footer-mobile">
                                        <div className="mobile-card-number">
                                            <p style={{ margin: 0 }}>
                                                {card.number}/{card.numberOfCards}
                                            </p>
                                        </div>

                                        <img
                                            loading="lazy"
                                            className="desktop-pokeball"
                                            src={
                                                card.isCardInCollection
                                                    ? assets.site.images.pokeballCaught
                                                    : assets.site.images.pokeball
                                            }
                                            alt=""
                                            onClick={() =>
                                                changeCardCollectionStatus(cardsToDisplay, card)
                                            }
                                        />
                                    </div>
                                    {showPriceData && (
                                        <div className="card-price">
                                            <p style={{ margin: 0 }}>
                                                ${card.market_price.toFixed(2)}
                                            </p>
                                        </div>
                                    )}
                                    {!showPriceData && (
                                        <div className="card-price">
                                            <p></p>
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </div>
                    ))}
            </div>

            {isModalOpen && selectedCard && (
                <CardTemplate cardInfo={selectedCard} onClose={handleCloseModal} />
            )}
        </>
    );
};

export default NEWMobileCardDisplay;
