import { toast } from 'react-toastify';
import { CameoCard, CategoryCard, FeaturedCard } from '../types/admin';

interface AdminApiResponse {
    success: boolean;
    message?: string;
}

interface GetCategoryCardsResponse extends AdminApiResponse {
    cards?: CategoryCard[];
}

interface GetFeaturedCardsResponse extends AdminApiResponse {
    cards?: FeaturedCard[];
}

interface CardCategoryStats extends AdminApiResponse {
    totalCards?: number;
    processedCards?: number;
}

interface FeaturedCardStats extends AdminApiResponse {
    totalCards?: number;
    featuredCards?: number;
}

interface CameoCardStats extends AdminApiResponse {
    totalCards?: number;
    cameoCards?: number;
}

interface GetCameoCardsResponse extends AdminApiResponse {
    cards?: CameoCard[];
}

interface GetCurrentFeaturedPokemonResponse extends AdminApiResponse {
    pokemon?: {
        id: number;
        name: string;
        picURL: string;
        number: string;
    }[];
}

export const getCardCategoryStats = async (authParams: any): Promise<CardCategoryStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`/api/admin/getCardCategoryStats?${queryParams}`);
        const result: CardCategoryStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch card category statistics');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching card statistics');
        return {
            success: false,
            totalCards: 0,
            processedCards: 0,
        };
    }
};

export const getCardCategoryPokemonCardData = async (
    authParams: any
): Promise<GetCategoryCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
        }).toString();

        const response = await fetch(`/api/admin/getCardCategoryPokemonCardData?${queryParams}`);
        const result: GetCategoryCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cards');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardCategory = async (
    authParams: any,
    cardId: number,
    cardType: string
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/updateCardCategory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                cardType,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update card category');
        } else {
            toast.success('Card category updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating card category');
        return { success: false };
    }
};

export const getFeaturedCardStats = async (authParams: any): Promise<FeaturedCardStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`/api/admin/getFeaturedCardStats?${queryParams}`);
        const result: FeaturedCardStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured card stats');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching featured card stats');
        return {
            success: false,
            totalCards: 0,
            featuredCards: 0,
        };
    }
};

export const getFeaturedPokemonCards = async (
    authParams: any
): Promise<GetFeaturedCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`/api/admin/getFeaturedPokemonCards?${queryParams}`);
        const result: GetFeaturedCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured cards');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching featured cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardFeatured = async (
    authParams: any,
    cardId: number,
    featuredPokemonId: number,
    hasCameoPokemon: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/updateCardFeatured', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                featuredPokemonId,
                hasCameoPokemon,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update featured status');
        } else {
            toast.success('Featured status updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating featured status');
        return { success: false };
    }
};

export const getCameoCardStats = async (authParams: any): Promise<CameoCardStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`/api/admin/getCameoCardStats?${queryParams}`);
        const result: CameoCardStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo card stats');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cameo statistics');
        return {
            success: false,
            totalCards: 0,
            cameoCards: 0,
        };
    }
};

export const getCameoPokemonCards = async (authParams: any): Promise<GetCameoCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`/api/admin/getCameoPokemonCards?${queryParams}`);
        const result: GetCameoCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo cards');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cameo cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardCameo = async (
    authParams: any,
    cardId: number,
    cameoPokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/updateCardCameo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                cameoPokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update cameo status');
        } else {
            toast.success('Cameo status updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating cameo status');
        return { success: false };
    }
};

export const getCurrentFeaturedPokemon = async (
    authParams: any,
    cardId: number
): Promise<GetCurrentFeaturedPokemonResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            cardId: cardId.toString(),
        }).toString();

        const response = await fetch(`/api/admin/getCurrentFeaturedPokemon?${queryParams}`);
        const result: GetCurrentFeaturedPokemonResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch current featured Pokemon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching current featured Pokemon');
        return {
            success: false,
            pokemon: [],
        };
    }
};

export const getCurrentCameoPokemon = async (
    authParams: any,
    cardId: number
): Promise<GetCurrentFeaturedPokemonResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            cardId: cardId.toString(),
        }).toString();

        const response = await fetch(`/api/admin/getCurrentCameoPokemon?${queryParams}`);
        const result: GetCurrentFeaturedPokemonResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch current cameo Pokemon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching current cameo Pokemon');
        return {
            success: false,
            pokemon: [],
        };
    }
};

export const completeFeaturedCard = async (
    authParams: any,
    cardId: number,
    hasCameoPokemon: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/completeFeaturedCard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                hasCameoPokemon,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to complete featured card');
        } else {
            toast.success('Card marked as complete');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while completing the featured card');
        return { success: false };
    }
};

export const completeCameoCard = async (
    authParams: any,
    cardId: number,
    isCameoComplete: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/completeCameoCard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                isCameoComplete,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to complete cameo card');
        } else {
            toast.success('Card marked as complete');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while completing the cameo card');
        return { success: false };
    }
};

export const removeFeaturedPokemon = async (
    authParams: any,
    cardId: number,
    pokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/removeFeaturedPokemon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                pokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to remove featured Pokemon');
        } else {
            toast.success('Featured Pokemon removed successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while removing featured Pokemon');
        return { success: false };
    }
};

export const removeCameoPokemon = async (
    authParams: any,
    cardId: number,
    pokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/admin/removeCameoPokemon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                pokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to remove cameo Pokemon');
        } else {
            toast.success('Cameo Pokemon removed successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while removing cameo Pokemon');
        return { success: false };
    }
};

// Add more admin API functions here as needed
