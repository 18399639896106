import React, { useState, useEffect, useCallback } from 'react';
import { Box, useScrollTrigger, Zoom, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import CardImageHeader from './CardImageHeader';
import CardImageDisplay from './CardImageCardDisplay';
import CardImageTemplate from './CardImageTemplate';
import { usePokemonSetFunctions } from '../../../../hooks/usePokemonSetFunctions';
import { PokemonCard } from '../../../../api/pokemonSetApi';
import CardSetSelector from './CardSetSelector';

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const CardImageManager: React.FC = () => {
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [selectedSetId, setSelectedSetId] = useState<number | null>(null);
    const [cards, setCards] = useState<PokemonCard[]>([]);
    const { getPokemonSetCards, loading } = usePokemonSetFunctions();
    const [page, setPage] = useState(1);

    useEffect(() => {
        setIsInitialLoading(false);
    }, []);

    const handleCardsUpdate = (updatedCards: PokemonCard[]) => {
        setCards(updatedCards);
    };

    const handleSetChange = async (setId: number | null) => {
        setSelectedSetId(setId);
        setPage(1);

        if (setId) {
            const result = await getPokemonSetCards(setId);
            setCards(result || []);
        } else {
            setCards([]);
        }
    };

    if (isInitialLoading || loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <CardImageHeader />
            <CardSetSelector selectedSetId={selectedSetId} onSetChange={handleSetChange} />
            <CardImageDisplay
                cards={cards}
                page={page}
                setPage={setPage}
                onCardsUpdate={handleCardsUpdate}
            />
            <ScrollTop />
        </>
    );
};

export default CardImageManager;
