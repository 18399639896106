import assets from '../../../assets';

export const EnglishDefaultSet = {
    type: 'WOTC',
    setName: 'Test Set',
    setSeries: 'Test Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 25,
        setNumber: 25,
        numOfReverseSet: 25,
        numberOfSubSet: 25,
    },
    logo: assets.english.images.baseSetLogo,
    releaseDate: '9999 December 31st',
    description: '',
};

export const ScarletViolet = {
    type: 'PokemonCompany',
    setName: 'Scarlet Violet',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 258,
        setNumber: 198,
        numOfReverseSet: 186,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ScarletVioletLogo,
    icon: assets.english.images.ScarletVioletIcon,
    releaseDate: '2023 March 31st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv01-scarlet-and-violet-base-set?productLineName=pokemon&setName=sv01-scarlet-and-violet-base-set&page=1&view=grid',
};

export const PaldeaEvolved = {
    type: 'PokemonCompany',
    setName: 'Paldea Evolved',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 279,
        setNumber: 193,
        numOfReverseSet: 176,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PaldeaEvolvedLogo,
    icon: assets.english.images.PaldeaEvolvedIcon,
    releaseDate: '2023 June 9th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv02-paldea-evolved?productLineName=pokemon&setName=sv02-paldea-evolved&page=1&view=grid',
};

export const ObsidianFlames = {
    type: 'PokemonCompany',
    setName: 'Obsidian Flames',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 230,
        setNumber: 197,
        numOfReverseSet: 176,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ObsidianFlamesLogo,
    icon: assets.english.images.ObsidianFlamesIcon,
    releaseDate: '2023 August 11th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv03-obsidian-flames?productLineName=pokemon&setName=sv03-obsidian-flames&page=1&view=grid',
};

export const ScarletViolet151 = {
    type: 'PokemonCompany',
    setName: 'ScarletViolet151',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 207,
        setNumber: 165,
        numOfReverseSet: 153,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ScarletViolet151Logo,
    icon: assets.english.images.ScarletViolet151Icon,
    releaseDate: '2023 September 22nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv-scarlet-and-violet-151?productLineName=pokemon&setName=sv-scarlet-and-violet-151&page=1&view=grid',
};

export const ParadoxRift = {
    type: 'PokemonCompany',
    setName: 'Paradox Rift',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 266,
        setNumber: 182,
        numOfReverseSet: 162,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ParadoxRiftLogo,
    icon: assets.english.images.ParadoxRiftIcon,
    releaseDate: '2023 November 3rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv04-paradox-rift?productLineName=pokemon&setName=sv04-paradox-rift&page=1&view=grid',
};

export const PaldeanFates = {
    type: 'PokemonCompany',
    setName: 'Paldean Fates',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 91,
        setNumber: 91,
        numOfReverseSet: 81,
        numberOfSubSet: 154,
    },
    logo: assets.english.images.PaldeanFatesLogo,
    icon: assets.english.images.PaldeanFatesIcon,
    releaseDate: '2024 January 26th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv-paldean-fates?productLineName=pokemon&setName=sv-paldean-fates&page=1&view=grid',
};

export const TemporalForces = {
    type: 'PokemonCompany',
    setName: 'Temporal Forces',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 218,
        setNumber: 162,
        numOfReverseSet: 143,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.TemporalForcesLogo,
    icon: assets.english.images.TemporalForcesIcon,
    releaseDate: '2024 March 22nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv05-temporal-forces?productLineName=pokemon&setName=sv05-temporal-forces&view=grid',
};

export const TwilightMasquerade = {
    type: 'PokemonCompany',
    setName: 'Twilight Masquerade',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 226,
        setNumber: 167,
        numOfReverseSet: 147,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.TwilightMasqueradeLogo,
    icon: assets.english.images.TwilightMasqueradeIcon,
    releaseDate: '2024 May 24th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv06-twilight-masquerade?view=grid&productLineName=pokemon&setName=sv06-twilight-masquerade',
};

export const ShroudedFable = {
    type: 'PokemonCompany',
    setName: 'Shrouded Fable',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 99,
        setNumber: 64,
        numOfReverseSet: 55,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ShroudedFableLogo,
    icon: assets.english.images.ShroudedFableIcon,
    releaseDate: '2024 August 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv-shrouded-fable?productLineName=pokemon&setName=sv-shrouded-fable&Language=English&view=grid',
};

export const StellarCrown = {
    type: 'PokemonCompany',
    setName: 'Stellar Crown',
    setSeries: 'Scarlet Violet Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 175,
        setNumber: 142,
        numOfReverseSet: 125,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.StellarCrownLogo,
    icon: assets.english.images.StellarCrownIcon,
    releaseDate: '2024 September 13th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sv07-stellar-crown?view=grid&productLineName=pokemon&setName=sv07-stellar-crown',
};

export const SwordShield = {
    setName: 'Sword Shield',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 216,
        setNumber: 202,
        numOfReverseSet: 165,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.SwordShieldLogo,
    icon: assets.english.images.SwordShieldIcon,
    releaseDate: '2020 February 7th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh01-sword-and-shield-base-set?productLineName=pokemon&setName=swsh01-sword-and-shield-base-set&page=1&view=grid',
};

export const RebelClash = {
    setName: 'Rebel Clash',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 209,
        setNumber: 192,
        numOfReverseSet: 151,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.RebelClashLogo,
    icon: assets.english.images.RebelClashIcon,
    releaseDate: '2020 May 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh02-rebel-clash?productLineName=pokemon&setName=swsh02-rebel-clash&page=1&view=grid',
};

export const DarknessAblaze = {
    setName: 'Darkness Ablaze',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 201,
        setNumber: 189,
        numOfReverseSet: 155,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DarknessAblazeLogo,
    icon: assets.english.images.DarknessAblazeIcon,
    releaseDate: '2020 August 14th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh03-darkness-ablaze?productLineName=pokemon&setName=swsh03-darkness-ablaze&page=1&view=grid',
};

export const ChampionsPath = {
    setName: 'Champions Path',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 80,
        setNumber: 73,
        numOfReverseSet: 54,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ChampionsPathLogo,
    icon: assets.english.images.ChampionsPathIcon,
    releaseDate: '2020 September 25th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/champions-path?productLineName=pokemon&setName=champions-path&page=1&view=grid',
};

export const VividVoltage = {
    setName: 'Vivid Voltage',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 203,
        setNumber: 185,
        numOfReverseSet: 142,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.VividVoltageLogo,
    icon: assets.english.images.VividVoltageIcon,
    releaseDate: '2020 November 13th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh04-vivid-voltage?productLineName=pokemon&setName=swsh04-vivid-voltage&page=1&view=grid',
};

export const ShiningFates = {
    setName: 'ShiningFates',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 73,
        setNumber: 72,
        numOfReverseSet: 46,
        numberOfSubSet: 122,
    },
    logo: assets.english.images.ShiningFatesLogo,
    icon: assets.english.images.ShiningFatesIcon,
    releaseDate: '2021 February 19th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=shining-fates|shining-fates-shiny-vault&page=1&view=grid',
};

export const BattleStyles = {
    setName: 'Battle Styles',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 183,
        setNumber: 163,
        numOfReverseSet: 123,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BattleStylesLogo,
    icon: assets.english.images.BattleStylesIcon,
    releaseDate: '2021 March 19th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh05-battle-styles?productLineName=pokemon&setName=swsh05-battle-styles&page=1&view=grid',
};

export const ChillingReign = {
    setName: 'Chilling Reign',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 234,
        setNumber: 198,
        numOfReverseSet: 136,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ChillingReignLogo,
    icon: assets.english.images.ChillingReignIcon,
    releaseDate: '2021 June 18th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh06-chilling-reign?productLineName=pokemon&setName=swsh06-chilling-reign&page=1&view=grid',
};

export const EvolvingSkies = {
    setName: 'Evolving Skies',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 237,
        setNumber: 203,
        numOfReverseSet: 132,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EvolvingSkiesLogo,
    icon: assets.english.images.EvolvingSkiesIcon,
    releaseDate: '2021 August 27th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh07-evolving-skies?productLineName=pokemon&setName=swsh07-evolving-skies&page=1&view=grid',
};

export const Celebrations = {
    setName: 'Celebrations',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 25,
        setNumber: 25,
        numOfReverseSet: 0,
        numberOfSubSet: 25,
    },
    logo: assets.english.images.CelebrationsLogo,
    icon: assets.english.images.CelebrationsIcon,
    releaseDate: '2021 October 8th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/celebrations-classic-collection?productLineName=pokemon&setName=celebrations-classic-collection&page=1&view=grid',
};

export const FusionStrike = {
    setName: 'Fusion Strike',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 284,
        setNumber: 264,
        numOfReverseSet: 217,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.FusionStrikeLogo,
    icon: assets.english.images.FusionStrikeIcon,
    releaseDate: '2021 November 12th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/swsh08-fusion-strike?productLineName=pokemon&setName=swsh08-fusion-strike&page=1&view=grid',
};

export const BrilliantStars = {
    setName: 'Brilliant Stars',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 186,
        setNumber: 172,
        numOfReverseSet: 124,
        numberOfSubSet: 30,
    },
    logo: assets.english.images.BrilliantStarsLogo,
    icon: assets.english.images.BrilliantStarsIcon,
    releaseDate: '2022 February 25th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=swsh09-brilliant-stars|swsh09-brilliant-stars-trainer-gallery&page=1&view=grid',
};

export const AstralRadiance = {
    setName: 'Astral Radiance',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 216,
        setNumber: 189,
        numOfReverseSet: 132,
        numberOfSubSet: 30,
    },
    logo: assets.english.images.AstralRadianceLogo,
    icon: assets.english.images.AstralRadianceIcon,
    releaseDate: '2022 May 27th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=swsh10-astral-radiance|swsh10-astral-radiance-trainer-gallery&page=1&view=grid',
};

export const PokemonGO = {
    setName: 'Pokemon Go',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 88,
        setNumber: 78,
        numOfReverseSet: 57,
        numberOfSubSet: 3,
    },
    logo: assets.english.images.PokemonGoLogo,
    icon: assets.english.images.PokemonGoIcon,
    releaseDate: '2022 July 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/pokemon-go?productLineName=pokemon&setName=pokemon-go&page=1&view=grid',
};

export const LostOrigin = {
    setName: 'Lost Origin',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 217,
        setNumber: 196,
        numOfReverseSet: 149,
        numberOfSubSet: 30,
    },
    logo: assets.english.images.LostOriginLogo,
    icon: assets.english.images.LostOriginIcon,
    releaseDate: '2022 September 9th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=swsh11-lost-origin|swsh11-lost-origin-trainer-gallery&page=1&view=grid',
};

export const SilverTempest = {
    setName: 'Silver Tempest',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 215,
        setNumber: 195,
        numOfReverseSet: 142,
        numberOfSubSet: 30,
    },
    logo: assets.english.images.SilverTempestLogo,
    icon: assets.english.images.SilverTempestIcon,
    releaseDate: '2022 November 11th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=swsh12-silver-tempest|swsh12-silver-tempest-trainer-gallery&page=1&view=grid',
};

export const CrownZenith = {
    setName: 'Crown Zenith',
    setSeries: 'Sword Shield Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 161,
        setNumber: 159,
        numOfReverseSet: 112,
        numberOfSubSet: 70,
    },
    logo: assets.english.images.CrownZenithLogo,
    icon: assets.english.images.CrownZenithIcon,
    releaseDate: '2023 January 20th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&setName=crown-zenith-galarian-gallery|crown-zenith&page=1&view=grid',
};

export const SunMoon = {
    setName: 'Sun Moon',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 163,
        setNumber: 149,
        numOfReverseSet: 126,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.SunMoonLogo,
    icon: assets.english.images.SunMoonIcon,
    releaseDate: '2017 February 3th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-base-set?productLineName=pokemon&setName=sm-base-set&page=1&view=grid',
};

export const GuardiansRising = {
    setName: 'Guardians Rising',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 169,
        setNumber: 145,
        numOfReverseSet: 118,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.GuardiansRisingLogo,
    icon: assets.english.images.GuardiansRisingIcon,
    releaseDate: '2017 May 5th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-guardians-rising?productLineName=pokemon&page=1&view=grid&setName=sm-guardians-rising',
};

export const BurningShadows = {
    setName: 'Burning Shadows',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 169,
        setNumber: 147,
        numOfReverseSet: 116,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BurningShadowsLogo,
    icon: assets.english.images.BurningShadowsIcon,
    releaseDate: '2017 August 4th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-burning-shadows?productLineName=pokemon&page=1&view=grid&setName=sm-burning-shadows',
};

export const ShiningLegends = {
    setName: 'Shining Legends',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 78,
        setNumber: 73,
        numOfReverseSet: 60,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ShiningLegendsLogo,
    icon: assets.english.images.ShiningLegendsIcon,
    releaseDate: '2017 October 6th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/shining-legends?productLineName=pokemon&page=1&view=grid&setName=shining-legends',
};

export const CrimsonInvasion = {
    setName: 'Crimson Invasion',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 124,
        setNumber: 111,
        numOfReverseSet: 92,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.CrimsonInvasionLogo,
    icon: assets.english.images.CrimsonInvasionIcon,
    releaseDate: '2017 November 3rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-crimson-invasion?productLineName=pokemon&page=1&view=grid&setName=sm-crimson-invasion',
};

export const UltraPrism = {
    setName: 'Ultra Prism',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 173,
        setNumber: 156,
        numOfReverseSet: 125,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.UltraPrismLogo,
    icon: assets.english.images.UltraPrismIcon,
    releaseDate: '2018 February 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-ultra-prism?productLineName=pokemon&page=1&view=grid&setName=sm-ultra-prism',
};

export const ForbiddenLight = {
    setName: 'Forbidden Light',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 146,
        setNumber: 131,
        numOfReverseSet: 105,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.ForbiddenLightLogo,
    icon: assets.english.images.ForbiddenLightIcon,
    releaseDate: '2018 May 4th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-forbidden-light?productLineName=pokemon&page=1&view=grid&setName=sm-forbidden-light',
};

export const CelestialStorm = {
    setName: 'Celestial Storm',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 183,
        setNumber: 168,
        numOfReverseSet: 137,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.CelestialStormLogo,
    icon: assets.english.images.CelestialStormIcon,
    releaseDate: '2018 August 4rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-celestial-storm?productLineName=pokemon&page=1&view=grid&setName=sm-celestial-storm',
};

export const DragonMajesty = {
    setName: 'Dragon Majesty',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 999,
        setNumber: 999,
        numOfReverseSet: 999,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DragonMajestyLogo,
    icon: assets.english.images.DragonMajestyIcon,
    releaseDate: '2018 September 7th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dragon-majesty?productLineName=pokemon&page=1&view=grid&setName=dragon-majesty',
};

export const LostThunder = {
    setName: 'Lost Thunder',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 236,
        setNumber: 214,
        numOfReverseSet: 174,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.LostThunderLogo,
    icon: assets.english.images.LostThunderIcon,
    releaseDate: '2018 November 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-lost-thunder?productLineName=pokemon&page=1&view=grid&setName=sm-lost-thunder',
};

export const TeamUp = {
    setName: 'Team Up',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 196,
        setNumber: 181,
        numOfReverseSet: 143,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.TeamUpLogo,
    icon: assets.english.images.TeamUpIcon,
    releaseDate: '2019 February 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-team-up?productLineName=pokemon&page=1&view=grid&setName=sm-team-up',
};

export const DetectivePikachu = {
    setName: 'Detective Pikachu',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 18,
        setNumber: 18,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DetectivePikachuLogo,
    icon: assets.english.images.DetectivePikachuIcon,
    releaseDate: '2019 March 29th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/detective-pikachu?productLineName=pokemon&page=1&view=grid&setName=detective-pikachu',
};

export const UnbrokenBonds = {
    setName: 'Unbroken Bonds',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 214,
        setNumber: 214,
        numOfReverseSet: 176,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.UnbrokenBondsLogo,
    icon: assets.english.images.UnbrokenBondsIcon,
    releaseDate: '2019 May 3rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-unbroken-bonds?productLineName=pokemon&page=1&view=grid&setName=sm-unbroken-bonds',
};

export const UnifiedMinds = {
    setName: 'Unified Minds',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 258,
        setNumber: 236,
        numOfReverseSet: 196,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.UnifiedMindsLogo,
    icon: assets.english.images.UnifiedMindsIcon,
    releaseDate: '2019 August 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-unified-minds?productLineName=pokemon&page=1&view=grid&setName=sm-unified-minds',
};

export const HiddenFates = {
    setName: 'Hidden Fates',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 69,
        setNumber: 68,
        numOfReverseSet: 56,
        numberOfSubSet: 94,
    },
    logo: assets.english.images.HiddenFatesLogo,
    icon: assets.english.images.HiddenFatesIcon,
    releaseDate: '2019 August 23rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&page=1&view=grid&setName=hidden-fates|hidden-fates-shiny-vault',
};

export const CosmicEclipse = {
    setName: 'Cosmic Eclipse',
    setSeries: 'Sun Moon Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 271,
        setNumber: 236,
        numOfReverseSet: 193,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.CosmicEclipseLogo,
    icon: assets.english.images.CosmicEclipseIcon,
    releaseDate: '2019 November 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sm-cosmic-eclipse?productLineName=pokemon&page=1&view=grid&setName=sm-cosmic-eclipse',
};

export const XY = {
    setName: 'XY',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 147,
        setNumber: 146,
        numOfReverseSet: 123,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.XYLogo,
    icon: assets.english.images.XYIcon,
    releaseDate: '2014 February 5th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-base-set?productLineName=pokemon&page=1&view=grid&setName=xy-base-set',
};

export const Flashfire = {
    setName: 'Flashfire',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 109,
        setNumber: 106,
        numOfReverseSet: 91,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.FlashFireLogo,
    icon: assets.english.images.FlashFireIcon,
    releaseDate: '2014 May 7th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-flashfire?productLineName=pokemon&page=1&view=grid&setName=xy-flashfire',
};

export const FuriousFists = {
    setName: 'Furious Fists',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 113,
        setNumber: 111,
        numOfReverseSet: 97,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.FuriousFistsLogo,
    icon: assets.english.images.FuriousFistsIcon,
    releaseDate: '2014 May 7th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-furious-fists?productLineName=pokemon&page=1&view=grid&setName=xy-furious-fists',
};

export const PhantomForces = {
    setName: 'Phantom Forces',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 122,
        setNumber: 119,
        numOfReverseSet: 102,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PhantonForcesLogo,
    icon: assets.english.images.PhantonForcesIcon,
    releaseDate: '2014 November 5th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-phantom-forces?productLineName=pokemon&page=1&view=grid&setName=xy-phantom-forces',
};

export const PrimalClash = {
    setName: 'Primal Clash',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 164,
        setNumber: 160,
        numOfReverseSet: 132,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PrimalClashLogo,
    icon: assets.english.images.PrimalClashIcon,
    releaseDate: '2015 February 4th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-primal-clash?productLineName=pokemon&page=1&view=grid&setName=xy-primal-clash',
};

export const RoaringSkies = {
    setName: 'Roaring Skies',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 110,
        setNumber: 109,
        numOfReverseSet: 86,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.RoaringSkiesLogo,
    icon: assets.english.images.RoaringSkiesIcon,
    releaseDate: '2015 May 6th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-roaring-skies?productLineName=pokemon&page=1&view=grid&setName=xy-roaring-skies',
};

export const AncientOrigins = {
    setName: 'Ancient Origins',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 100,
        setNumber: 100,
        numOfReverseSet: 72,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.AncientOriginsLogo,
    icon: assets.english.images.AncientOriginsIcon,
    releaseDate: '2015 August 12th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-ancient-origins?productLineName=pokemon&page=1&view=grid&setName=xy-ancient-origins',
};

export const BreakThrough = {
    setName: 'BreakThrough',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 164,
        setNumber: 162,
        numOfReverseSet: 138,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BreakThroughLogo,
    icon: assets.english.images.BreakThroughIcon,
    releaseDate: '2015 November 4th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-breakthrough?productLineName=pokemon&page=1&view=grid&setName=xy-breakthrough',
};

export const BreakPoint = {
    setName: 'BreakPoint',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 123,
        setNumber: 122,
        numOfReverseSet: 97,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BreakPointLogo,
    icon: assets.english.images.BreakPointIcon,
    releaseDate: '2016 February 3rd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-breakpoint?productLineName=pokemon&page=1&view=grid&setName=xy-breakpoint',
};

export const FatesCollide = {
    setName: 'Fates Collide',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 125,
        setNumber: 124,
        numOfReverseSet: 97,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.FatesCollideLogo,
    icon: assets.english.images.FatesCollideIcon,
    releaseDate: '2016 May 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-fates-collide?productLineName=pokemon&page=1&view=grid&setName=xy-fates-collide',
};

export const SteamSiege = {
    setName: 'Steam Siege',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 116,
        setNumber: 114,
        numOfReverseSet: 93,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.SteamSiegeLogo,
    icon: assets.english.images.SteamSiegeIcon,
    releaseDate: '2016 May 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-steam-siege?productLineName=pokemon&page=1&view=grid&setName=xy-steam-siege',
};

export const Evolutions = {
    setName: 'Evolutions',
    setSeries: 'XY Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 113,
        setNumber: 108,
        numOfReverseSet: 83,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EvolutionsLogo,
    icon: assets.english.images.EvolutionsIcon,
    releaseDate: '2016 November 2nd',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/xy-evolutions?productLineName=pokemon&page=1&view=grid&setName=xy-evolutions',
};

export const BlackWhite = {
    setName: 'Black White',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 115,
        setNumber: 114,
        numOfReverseSet: 104,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BlackWhiteLogo,
    icon: assets.english.images.BlackWhiteIcon,
    releaseDate: '2011 April 25th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/black-and-white?productLineName=pokemon&page=1&view=grid&setName=black-and-white',
};

export const EmergingPowers = {
    setName: 'Emerging Powers',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 98,
        setNumber: 98,
        numOfReverseSet: 96,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EmergingPowersLogo,
    icon: assets.english.images.EmergingPowersIcon,
    releaseDate: '2011 August 31st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/emerging-powers?productLineName=pokemon&page=1&view=grid&setName=emerging-powers',
};

export const NobleVictories = {
    setName: 'Noble Victories',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 102,
        setNumber: 101,
        numOfReverseSet: 96,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.NobleVictoriesLogo,
    icon: assets.english.images.NobleVictoriesIcon,
    releaseDate: '2011 November 16th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/noble-victories?productLineName=pokemon&page=1&view=grid&setName=noble-victories',
};

export const NextDestinies = {
    setName: 'Next Destinies',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 103,
        setNumber: 99,
        numOfReverseSet: 87,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.NextDestiniesLogo,
    icon: assets.english.images.NextDestiniesIcon,
    releaseDate: '2012 February 8th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/next-destinies?productLineName=pokemon&page=1&view=grid&setName=next-destinies',
};

export const DarkExplorers = {
    setName: 'Dark Explorers',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 111,
        setNumber: 108,
        numOfReverseSet: 96,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DarkExplorersLogo,
    icon: assets.english.images.DarkExplorersIcon,
    releaseDate: '2012 May 9th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dark-explorers?productLineName=pokemon&page=1&view=grid&setName=dark-explorers',
};

export const DragonsExalted = {
    setName: 'Dragons Exalted',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 128,
        setNumber: 124,
        numOfReverseSet: 112,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DragonsExaltedLogo,
    icon: assets.english.images.DragonsExaltedIcon,
    releaseDate: '2012 August 15th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dragons-exalted?productLineName=pokemon&page=1&view=grid&setName=dragons-exalted',
};

export const DragonVault = {
    setName: 'Dragon Vault',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 21,
        setNumber: 20,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DragonVaultLogo,
    icon: assets.english.images.DragonVaultIcon,
    releaseDate: '2012 August 15th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dragon-vault?productLineName=pokemon&page=1&view=grid&setName=dragon-vault',
};

export const BoundariesCrossed = {
    setName: 'Boundaries Crossed',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 153,
        setNumber: 149,
        numOfReverseSet: 130,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.BoundariesCrossedLogo,
    icon: assets.english.images.BoundariesCrossedIcon,
    releaseDate: '2012 November 7th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/boundaries-crossed?productLineName=pokemon&page=1&view=grid&setName=boundaries-crossed',
};

export const PlasmaStorm = {
    setName: 'Plasma Storm',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 138,
        setNumber: 135,
        numOfReverseSet: 119,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PlasmaStormLogo,
    icon: assets.english.images.PlasmaStormIcon,
    releaseDate: '2013 February 6th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/plasma-storm?productLineName=pokemon&page=1&view=grid&setName=plasma-storm',
};

export const PlasmaFreeze = {
    setName: 'Plasma Freeze',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 122,
        setNumber: 115,
        numOfReverseSet: 100,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PlasmaFreezeLogo,
    icon: assets.english.images.PlasmaFreezeIcon,
    releaseDate: '2013 May 8th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/plasma-freeze?productLineName=pokemon&page=1&view=grid&setName=plasma-freeze',
};

export const PlasmaBlast = {
    setName: 'Plasma Blast',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 105,
        setNumber: 101,
        numOfReverseSet: 85,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.PlasmaBlastLogo,
    icon: assets.english.images.PlasmaBlastIcon,
    releaseDate: '2013 August 14th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/plasma-blast?productLineName=pokemon&page=1&view=grid&setName=plasma-blast',
};

export const LegendaryTreasures = {
    setName: 'Legendary Treasures',
    setSeries: 'Black White Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 115,
        setNumber: 113,
        numOfReverseSet: 101,
        numberOfSubSet: 25,
    },
    logo: assets.english.images.LegendaryTreasuresLogo,
    icon: assets.english.images.LegendaryTreasuresIcon,
    releaseDate: '2013 November 6th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&page=1&view=grid&setName=legendary-treasures|legendary-treasures-radiant-collection',
};

export const HeartGoldSoulSilver = {
    setName: 'HeartGold SoulSilver',
    setSeries: 'HeartGold SoulSilver Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 124,
        setNumber: 123,
        numOfReverseSet: 105,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.HeartGoldSoulSilverLogo,
    icon: assets.english.images.heartGoldSoulSilverIcon,
    releaseDate: '2010 February 10th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/heartgold-soulsilver?productLineName=pokemon&page=1&view=grid&setName=heartgold-soulsilver',
};

export const Unleashed = {
    setName: 'Unleashed',
    setSeries: 'HeartGold SoulSilver Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 96,
        setNumber: 95,
        numOfReverseSet: 83,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.UnleashedLogo,
    icon: assets.english.images.UnleashedIcon,
    releaseDate: '2010 February 10th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/unleashed?productLineName=pokemon&page=1&view=grid&setName=unleashed',
};

export const Undaunted = {
    setName: 'Undaunted',
    setSeries: 'HeartGold SoulSilver Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 91,
        setNumber: 90,
        numOfReverseSet: 80,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.UndauntedLogo,
    icon: assets.english.images.UndauntedIcon,
    releaseDate: '2010 February 10th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/undaunted?productLineName=pokemon&page=1&view=grid&setName=undaunted',
};

export const Triumphant = {
    setName: 'Triumphant',
    setSeries: 'HeartGold SoulSilver Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 103,
        setNumber: 102,
        numOfReverseSet: 90,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.TriumphantLogo,
    icon: assets.english.images.TriumphantIcon,
    releaseDate: '2010 February 10th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/triumphant?productLineName=pokemon&page=1&view=grid&setName=triumphant',
};

export const CallOfLegends = {
    setName: 'Call of Legends',
    setSeries: 'HeartGold SoulSilver Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 95,
        setNumber: 95,
        numOfReverseSet: 87,
        numberOfSubSet: 11,
    },
    logo: assets.english.images.CallofLegendsLogo,
    icon: assets.english.images.CallofLegendsIcon,
    releaseDate: '2010 February 10th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/call-of-legends?productLineName=pokemon&page=1&view=grid&setName=call-of-legends',
};

export const DiamondPearl = {
    setName: 'Diamond Pearl',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 130,
        setNumber: 130,
        numOfReverseSet: 127,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.DiamondPearlLogo,
    icon: assets.english.images.DiamondPearlIcon,
    releaseDate: '2007 May 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/diamond-and-pearl?productLineName=pokemon&page=1&view=grid&setName=diamond-and-pearl',
};

export const MysteriousTreasures = {
    setName: 'Mysterious Treasures',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 124,
        setNumber: 123,
        numOfReverseSet: 120,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.MysteriousTreasuresLogo,
    icon: assets.english.images.MysteriousTreasuresIcon,
    releaseDate: '2007 August 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/mysterious-treasures?productLineName=pokemon&page=1&view=grid&setName=mysterious-treasures',
};

export const SecretWonders = {
    setName: 'Secret Wonders',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 132,
        setNumber: 132,
        numOfReverseSet: 130,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.SecretWondersLogo,
    icon: assets.english.images.SecretWondersIcon,
    releaseDate: '2007 November 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/secret-wonders?productLineName=pokemon&page=1&view=grid&setName=secret-wonders',
};

export const GreatEncounters = {
    setName: 'Great Encounters',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 106,
        setNumber: 106,
        numOfReverseSet: 102,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.GreatEncountersLogo,
    icon: assets.english.images.GreatEncountersIcon,
    releaseDate: '2008 February 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/great-encounters?productLineName=pokemon&page=1&view=grid&setName=great-encounters',
};

export const MajesticDawn = {
    setName: 'Majestic Dawn',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 100,
        setNumber: 100,
        numOfReverseSet: 96,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.MajesticDawnLogo,
    icon: assets.english.images.MajesticDawnIcon,
    releaseDate: '2008 May 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/majestic-dawn?productLineName=pokemon&page=1&view=grid&setName=majestic-dawn',
};

export const LegendsAwakened = {
    setName: 'Legends Awakened',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 146,
        setNumber: 146,
        numOfReverseSet: 139,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.LegendsAwakenedLogo,
    icon: assets.english.images.LegendsAwakenedIcon,
    releaseDate: '2008 August 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/legends-awakened?productLineName=pokemon&page=1&view=grid&setName=legends-awakened',
};

export const Stormfront = {
    setName: 'Stormfront',
    setSeries: 'Diamond Pearl Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 103,
        setNumber: 100,
        numOfReverseSet: 95,
        numberOfSubSet: 3,
    },
    logo: assets.english.images.StormfrontLogo,
    icon: assets.english.images.StormfrontIcon,
    releaseDate: '2008 November 1st',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/stormfront?productLineName=pokemon&page=1&view=grid&setName=stormfront',
};

export const Platinum = {
    setName: 'Platinum',
    setSeries: 'Platinum Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 130,
        setNumber: 127,
        numOfReverseSet: 121,
        numberOfSubSet: 3,
    },
    logo: assets.english.images.PlatinumLogo,
    icon: assets.english.images.PlatinumIcon,
    releaseDate: '2009 February 11th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/platinum?productLineName=pokemon&page=1&view=grid&setName=platinum',
};

export const RisingRivals = {
    setName: 'Rising Rivals',
    setSeries: 'Platinum Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 114,
        setNumber: 111,
        numOfReverseSet: 102,
        numberOfSubSet: 6,
    },
    logo: assets.english.images.RisingRivalsLogo,
    icon: assets.english.images.RisingRivalsIcon,
    releaseDate: '2009 May 16th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/rising-rivals?productLineName=pokemon&page=1&view=grid&setName=rising-rivals',
};

export const SupremeVictors = {
    setName: 'Supreme Victors',
    setSeries: 'Platinum Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 150,
        setNumber: 147,
        numOfReverseSet: 140,
        numberOfSubSet: 3,
    },
    logo: assets.english.images.SupremeVictorsLogo,
    icon: assets.english.images.SupremeVictorsIcon,
    releaseDate: '2009 August 19th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/supreme-victors?productLineName=pokemon&page=1&view=grid&setName=supreme-victors',
};

export const Arceus = {
    setName: 'Arceus',
    setSeries: 'Platinum Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 99,
        setNumber: 99,
        numOfReverseSet: 93,
        numberOfSubSet: 12,
    },
    logo: assets.english.images.ArceusLogo,
    icon: assets.english.images.ArceusIcon,
    releaseDate: '2009 November 4th',
    description: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/arceus?productLineName=pokemon&page=1&view=grid&setName=arceus',
};

export const EXRubySapphire = {
    type: 'WOTC',
    setName: 'EX Ruby Sapphire',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 109,
        setNumber: 109,
        numOfReverseSet: 101,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXRubySapphireLogo,
    icon: assets.english.images.EXRubySapphireIcon,
    releaseDate: '2003 July 1st',
    description:
        'EX Ruby & Sapphire Set (2003): The EX Ruby & Sapphire Set was the 1st set of Pokémon TCG cards to be released in English EX Series. It featured 109 cards and introduced new Pokémon from the Hoenn region, as well as new gameplay mechanics such as Pokémon-ex Pokémon.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/ruby-and-sapphire?productLineName=pokemon&page=1&view=grid&setName=ruby-and-sapphire',
};

export const EXSandstorm = {
    type: 'WOTC',
    setName: 'EX Sandstorm',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 100,
        setNumber: 100,
        numOfReverseSet: 93,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXSandstormLogo,
    icon: assets.english.images.EXSandstormIcon,
    releaseDate: '2003 September 18th',
    description:
        'EX Sandstorm Set (2003): The EX Sandstorm Set was the 2nd set of Pokémon TCG cards to be released in English EX Series. It featured 100 cards and introduced new Pokémon from the Hoenn region, as well as new gameplay mechanics such as Pokémon-ex',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/sandstorm?productLineName=pokemon&page=1&view=grid&setName=sandstorm',
};

export const EXDragon = {
    type: 'WOTC',
    setName: 'EX Dragon',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 100,
        setNumber: 100,
        numOfReverseSet: 88,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXDragonLogo,
    icon: assets.english.images.EXDragonIcon,
    releaseDate: '2003 November 3rd',
    description:
        'EX Dragon Set (2003): The EX Dragon Set was the 3rd set of Pokémon TCG cards to be released in English EX Series. It featured 97 cards and introduced new Pokémon from the Hoenn region, as well as new gameplay mechanics such as Pokémon-ex and Legendary Pokémon.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dragon?productLineName=pokemon&page=1&view=grid&setName=dragon',
};

export const EXTeamMagmavsTeamAqua = {
    type: 'WOTC',
    setName: 'EX Team Magma vs Team Aqua',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 97,
        setNumber: 97,
        numOfReverseSet: 88,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXTeamMagmavsTeamAquaLogo,
    icon: assets.english.images.EXTeamMagmavsTeamAquaIcon,
    releaseDate: '2004 March 4th',
    description:
        'EX Team Magma vs Team Aqua Set (2004): The EX Team Magma vs Team Aqua Set was the 4th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/team-magma-vs-team-aqua?productLineName=pokemon&page=1&view=grid&setName=team-magma-vs-team-aqua',
};

export const EXHiddenLegends = {
    type: 'WOTC',
    setName: 'EX Hidden Legends',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 102,
        setNumber: 102,
        numOfReverseSet: 92,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXHiddenLegendsLogo,
    icon: assets.english.images.EXHiddenLegendsIcon,
    releaseDate: '2004 June 1st',
    description:
        'EX Hidden Legends Set (2004): The EX Hidden Legends Set was the 5th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/hidden-legends?productLineName=pokemon&page=1&view=grid&setName=hidden-legends',
};

export const EXFireRedLeafGreen = {
    type: 'WOTC',
    setName: 'EX FireRed LeafGreen',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 116,
        setNumber: 116,
        numOfReverseSet: 103,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXRireRedLeafGreenLogo,
    icon: assets.english.images.EXRireRedLeafGreenIcon,
    releaseDate: '2004 September 1st',
    description:
        'EX FireRed & LeafGreen Set (2004): The EX FireRed & LeafGreen Set was the 6th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/firered-and-leafgreen?productLineName=pokemon&page=1&view=grid&setName=firered-and-leafgreen',
};

export const EXTeamRocketReturns = {
    type: 'WOTC',
    setName: 'EX Team Rocket Returns',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 111,
        setNumber: 111,
        numOfReverseSet: 95,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXTeamRocketReturnsLogo,
    icon: assets.english.images.EXTeamRocketReturnsIcon,
    releaseDate: '2004 November 1st',
    description:
        'EX Team Rocket Returns Set (2004): The EX Team Rocket Returns Set was the 7th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/team-rocket-returns?productLineName=pokemon&page=1&view=grid&setName=team-rocket-returns',
};

export const EXDeoxys = {
    type: 'WOTC',
    setName: 'EX Deoxys',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 108,
        setNumber: 108,
        numOfReverseSet: 95,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXDeoxysLogo,
    icon: assets.english.images.EXDeoxysIcon,
    releaseDate: '2005 February 1st',
    description:
        'EX Deoxys Set (2005): The EX Deoxys Set was the 8th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/deoxys?productLineName=pokemon&page=1&view=grid&setName=deoxys',
};

export const EXEmerald = {
    type: 'WOTC',
    setName: 'EX Emerald',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 107,
        setNumber: 106,
        numOfReverseSet: 89,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXEmeraldLogo,
    icon: assets.english.images.EXEmeraldIcon,
    releaseDate: '2005 May 1st',
    description:
        'EX Emerald Set (2005): The EX Emerald Set was the 9th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/emerald?productLineName=pokemon&page=1&view=grid&setName=emerald',
};

export const EXUnseenForces = {
    type: 'WOTC',
    setName: 'EX Unseen Forces',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 117,
        setNumber: 115,
        numOfReverseSet: 89,
        numberOfSubSet: 27,
    },
    logo: assets.english.images.EXUnseenForcesLogo,
    icon: assets.english.images.EXUnseenForcesIcon,
    releaseDate: '2005 August 1st',
    description:
        'EX Unseen Forces Set (2005): The EX Unseen Forces Set was the 10th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/unseen-forces?productLineName=pokemon&page=1&view=grid&setName=unseen-forces',
};

export const EXDeltaSpecies = {
    type: 'WOTC',
    setName: 'EX Delta Species',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 114,
        setNumber: 114,
        numOfReverseSet: 107,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXDeltaSpeciesLogo,
    icon: assets.english.images.EXDeltaSpeciesIcon,
    releaseDate: '2005 October 31st',
    description:
        'EX Delta Species Set (2005): The EX Delta Species Set was the 11th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/delta-species?productLineName=pokemon&page=1&view=grid&setName=delta-species',
};

export const EXLegendMaker = {
    type: 'WOTC',
    setName: 'EX Legend Maker',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 93,
        setNumber: 93,
        numOfReverseSet: 82,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXLegendMakerLogo,
    icon: assets.english.images.EXLegendMakerIcon,
    releaseDate: '2006 February 1st',
    description:
        'EX Legend Maker Set (2006): The EX Legend Maker Set was the 12th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/legend-maker?productLineName=pokemon&page=1&view=grid&setName=legend-maker',
};

export const EXHolonPhantoms = {
    type: 'WOTC',
    setName: 'EX Holon Phantoms',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 111,
        setNumber: 111,
        numOfReverseSet: 98,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXHolonPhantomsLogo,
    icon: assets.english.images.EXHolonPhantomsIcon,
    releaseDate: '2006 May 1st',
    description:
        'EX Holon Phantoms Set (2006): The EX Holon Phantoms Set was the 13th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/holon-phantoms?productLineName=pokemon&page=1&view=grid&setName=holon-phantoms',
};

export const EXCrystalGuardians = {
    type: 'WOTC',
    setName: 'EX Crystal Guardians',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 100,
        setNumber: 100,
        numOfReverseSet: 88,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXCrystalGuardiansLogo,
    icon: assets.english.images.EXCrystalGuardiansIcon,
    releaseDate: '2006 August 1st',
    description:
        'EX Crystal Guardians Set (2006): EX Crystal Guardians Set was the 14th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/crystal-guardians?productLineName=pokemon&page=1&view=grid&setName=crystal-guardians',
};

export const EXDragonFrontiers = {
    type: 'WOTC',
    setName: 'EX Dragon Frontiers',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 101,
        setNumber: 101,
        numOfReverseSet: 89,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXDragonFrontiersLogo,
    icon: assets.english.images.EXDragonFrontiersIcon,
    releaseDate: '2006 November 1st',
    description:
        'EX Dragon Frontiers Set (2006): EX Dragon Frontiers Set was the 15th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/dragon-frontiers?productLineName=pokemon&page=1&view=grid&setName=dragon-frontiers',
};

export const EXPowerKeepers = {
    type: 'WOTC',
    setName: 'EX Power Keepers',
    setSeries: 'EX Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 108,
        setNumber: 108,
        numOfReverseSet: 91,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.EXPowerKeepersLogo,
    icon: assets.english.images.EXPowerKeepersIcon,
    releaseDate: '2007 February 1st',
    description:
        'EX Power Keepers Set (2007): EX Power Keepers Set was the 16th set of Pokémon TCG cards to be released in English EX Series.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/power-keepers?productLineName=pokemon&page=1&view=grid&setName=power-keepers',
};

export const LegendaryCollection = {
    type: 'WOTC',
    setName: 'Legendary Collection',
    setSeries: 'Legendary Collection Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 110,
        setNumber: 110,
        numOfReverseSet: 110,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.legendaryCollectionLogo,
    icon: assets.english.images.legendaryCollectionIcon,
    releaseDate: '2002 May 24th',
    description:
        'The Legendary Collection is a special set of Pokémon TCG cards that features some of the most popular chase cards from previous sets.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/legendary-collection?productLineName=pokemon&page=1&view=grid&setName=legendary-collection',
};

export const Expedition = {
    type: 'WOTC',
    setName: 'Expedition',
    setSeries: 'eCard Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 165,
        setNumber: 165,
        numOfReverseSet: 159,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.expeditionLogo,
    icon: assets.english.images.expeditionIcon2,
    releaseDate: '2002 September 15th',
    description:
        'Expedition Set (2002): The Expedition Set was the eleventh set of Pokémon TCG cards to be released in English. It featured 165 cards and introduced new Pokémon from the Johto region, as well as new gameplay mechanics such as Pokémon-ex and Trainer cards.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/expedition?productLineName=pokemon&page=1&view=grid&setName=expedition',
};

export const Aquapolis = {
    type: 'WOTC',
    setName: 'Aquapolis',
    setSeries: 'eCard Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 154,
        setNumber: 147,
        numOfReverseSet: 151,
        numberOfSubSet: 32,
    },
    logo: assets.english.images.aquapolisLogo,
    icon: assets.english.images.aquapolisIcon,
    releaseDate: '2003 January 15th',
    description:
        'Aquapolis Set (2003): The Aquapolis Set was the twelfth set of Pokémon TCG cards to be released in English. It featured 147 cards and introduced new Pokémon from the Johto and Hoenn regions, as well as new gameplay mechanics such as Pokémon-ex and Stadium cards.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/aquapolis?productLineName=pokemon&page=1&view=grid&setName=aquapolis',
};

export const Skyridge = {
    type: 'WOTC',
    setName: 'Skyridge',
    setSeries: 'eCard Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Main Set',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 150,
        setNumber: 144,
        numOfReverseSet: 144,
        numberOfSubSet: 32,
    },
    logo: assets.english.images.skyridgeLogo,
    icon: assets.english.images.skyridgeIcon2,
    releaseDate: '2003 May 17th',
    description:
        'Skyridge Set (2003): The Skyridge Set was the thirteenth set of Pokémon TCG cards to be released in English. It featured 165 cards and introduced new Pokémon from the Johto and Hoenn regions, as well as new gameplay mechanics such as Pokémon-ex and Crystal Type Pokémon.',
    printRunDescription: '',
    showAllCards: true,
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/skyridge?productLineName=pokemon&page=1&view=grid&setName=skyridge',
};

export const NeoDestiny = {
    type: 'WOTC',
    setName: 'Neo Destiny',
    setSeries: 'Neo Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 113,
        setNumber: 105,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.neoDestinyLogo,
    icon: assets.english.images.neoDestinyIcon,
    releaseDate: '2002 February 28th',
    description:
        'Neo Destiny Set (2002): The Neo Destiny Set was the tenth set of Pokémon TCG cards to be released in English. It featured 111 cards and introduced new Pokémon from the Johto region, as well as new gameplay mechanics such as Pokémon-ex and Shining Pokémon.',
    printRunDescription: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/neo-destiny?productLineName=pokemon&page=1&view=grid&setName=neo-destiny',
};

export const NeoRevelation = {
    type: 'WOTC',
    setName: 'Neo Revelation',
    setSeries: 'Neo Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 66,
        setNumber: 66,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.neoRevelationLogo,
    icon: assets.english.images.neoRevelationIcon,
    releaseDate: '2001 September 21st',
    description:
        'Neo Revelation Set (2001): The Neo Revelation Set was the ninth set of Pokémon TCG cards to be released in English. It featured 65 cards and introduced new Pokémon from the Johto region, as well as new gameplay mechanics such as Pokémon-ex and Darkness Energy.',
    printRunDescription: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/neo-revelation?productLineName=pokemon&page=1&view=grid&setName=neo-revelation',
};

export const NeoDiscovery = {
    type: 'WOTC',
    setName: 'Neo Discovery',
    setSeries: 'Neo Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 75,
        setNumber: 75,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.neoDiscoveryLogo,
    icon: assets.english.images.neoDestinyIcon,
    releaseDate: '2001 June 1st',
    description:
        'Neo Discovery Set (2001): The Neo Discovery Set was the eighth set of Pokémon TCG cards to be released in English. It featured 75 cards and introduced new Pokémon from the Johto region, as well as new gameplay mechanics such as Pokémon-ex and Dark Pokémon.',
    printRunDescription: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/neo-discovery?productLineName=pokemon&page=1&view=grid&setName=neo-discovery',
};

export const NeoGenesis = {
    type: 'WOTC',
    setName: 'Neo Genesis',
    setSeries: 'Neo Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 111,
        setNumber: 111,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.neoGenesisLogo,
    icon: assets.english.images.neoGenesisIcon,
    releaseDate: '2000 December 16th',
    description:
        'Neo Genesis Set (2000): The Neo Genesis Set was the seventh set of Pokémon TCG cards to be released in English. It featured 111 cards and introduced new Pokémon from the Generation II games, as well as new gameplay mechanics such as Baby Pokémon and Pokémon-ex.',
    printRunDescription: '',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/neo-genesis?productLineName=pokemon&page=1&view=grid&setName=neo-genesis',
};

export const GymChallenge = {
    type: 'WOTC',
    setName: 'Gym Challenge',
    setSeries: 'Gym Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 132,
        setNumber: 132,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.gymChallengeLogo,
    icon: assets.english.images.gymChallengeIcon,
    releaseDate: '2000 October 16th',
    description:
        'Gym Challenge Set (2000): The Gym Challenge Set was the sixth set of Pokémon TCG cards to be released in English. It featured 132 cards and introduced new Pokémon from the Johto region, as well as new Gym Leader cards.',
    printRunDescription: 'English Gym Challenge Set has 2 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/gym-challenge?productLineName=pokemon&page=1&view=grid&setName=gym-challenge',
};

export const GymHeroes = {
    type: 'WOTC',
    setName: 'Gym Heroes',
    setSeries: 'Gym Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 132,
        setNumber: 132,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.gymHeroesLogo,
    icon: assets.english.images.gymHeroesIcon,
    releaseDate: '2000 August 14th',
    description:
        'Gym Heroes Set (2000): The Gym Heroes Set was the fifth set of Pokémon TCG cards to be released in English. It featured 132 cards and introduced the concept of Gym Leaders, powerful trainers with unique abilities',
    printRunDescription: 'English Gym Heroes Set has 2 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/gym-heroes?productLineName=pokemon&page=1&view=grid&setName=gym-heroes',
};

export const TeamRocket = {
    type: 'WOTC',
    setName: 'Team Rocket',
    setSeries: 'Base Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 83,
        setNumber: 82,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.teamRocketLogo,
    icon: assets.english.images.teamRocketIcon,
    releaseDate: '2000 April 24th',
    description:
        'Team Rocket Set (2000): The Team Rocket Set was the fifth set of Pokémon TCG cards to be released in English. It featured 82 cards and 1 secret rare card and introduced a new theme of darker, more powerful Pokémon from Team Rocket',
    printRunDescription: 'English Team Rocket Set has 2 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/team-rocket?productLineName=pokemon&page=1&view=grid&setName=team-rocket',
};

export const baseSet2 = {
    type: 'WOTC',
    setName: 'Base Set 2',
    setSeries: 'Base Series',
    setPrintRuns: {
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 130,
        setNumber: 130,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.baseSet2Logo,
    icon: assets.english.images.baseSet2Icon,
    releaseDate: '1999 October 10th',
    description:
        'Base Set 2 (2000): The Base Set 2 was the fouth set of Pokémon TCG cards to be released in English. It featured 130 cards and featured reprints of some of the most popular cards from the original Base Set, giving players a chance to add these iconic cards to their collection.',
    printRunDescription: 'English Base Set 2 only has a single print run.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/base-set-2?productLineName=pokemon&page=1&view=grid&setName=base-set-2',
};

export const Fossil = {
    type: 'WOTC',
    setName: 'Fossil',
    setSeries: 'Base Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 62,
        setNumber: 62,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.fossilLogo,
    icon: assets.english.images.fossilIcon,
    releaseDate: '1999 October 10th',
    description:
        'Fossil Set (1999): The Fossil Set was the third set of Pokémon TCG cards to be released in English. It featured 62 cards and introduced new Pokémon from the Fossil region.',
    printRunDescription: 'English Fossil Set has 2 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/fossil?productLineName=pokemon&page=1&view=grid&setName=fossil',
};

export const Jungle = {
    type: 'WOTC',
    setName: 'Jungle',
    setSeries: 'Base Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
    },
    cardInfo: {
        numOfMainSet: 64,
        setNumber: 64,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.jungleLogo,
    icon: assets.english.images.jungleIcon,
    releaseDate: '1999 June 16th',
    description:
        'Jungle Set (1999): The Jungle Set was the second set of Pokémon TCG cards to be released in English. It featured 64 cards and introduced new Pokémon from the Jungle region.',
    printRunDescription: 'English Jungle Set has 2 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/jungle?productLineName=pokemon&page=1&view=grid&setName=jungle',
};

export const BaseSet = {
    type: 'WOTC',
    setName: 'Base Set',
    setSeries: 'Base Series',
    setPrintRuns: {
        firstEditionSet: {
            buttonDisplay: '1st Edition',
            description:
                'First edition cards have a the first edition icon below the left corner of the card. First edition cards are missing the dark shadow on the right side of the Pokemon art box',
        },
        shadowlessSet: {
            buttonDisplay: 'Shadowless',
            description:
                'Shadowless cards are missing the dark shadow on the right side of the Pokemon art box. All first edition are shadowless however not all shadowless are first edition!',
        },
        unlimitedEditionSet: {
            buttonDisplay: 'Unlimited',
            description:
                'Unlimited cards have a have a shadow on the right side of the Pokemon art box',
        },
        fourthPrintSet: {
            buttonDisplay: '4th Print',
            description:
                '4th Print is near identical to Unlimited except for the copyright date at the bottom of the card. 4th Print cards will have the copyright date of 1999-2000',
        },
    },
    cardInfo: {
        numOfMainSet: 102,
        setNumber: 102,
        numOfReverseSet: 0,
        numberOfSubSet: 0,
    },
    logo: assets.english.images.baseSetLogo,
    releaseDate: '1999 January 9th',
    description:
        'Base Set was the first set of Pokémon TCG cards to be released in English. It featured 102 cards and included some of the most iconic Pokémon, such as Charizard and Pikachu.',
    printRunDescription: 'English Base Set has 4 different print runs.',
    showPriceData: true,
    betaShowFullCard: true,
    tcgPlayerSetLink:
        'https://www.tcgplayer.com/search/pokemon/product?productLineName=pokemon&page=1&view=grid&setName=base-set|base-set-shadowless',
};
