import { toast } from 'react-toastify';

interface UserApiResponse {
    success: boolean;
    exists?: boolean;
    message?: string;
}

interface FavoritePokemonResponse extends UserApiResponse {
    favoritePokemon?: {
        name: string;
        image: string;
        number: number;
        gen: number;
    };
}

interface UserDetailsResponse extends UserApiResponse {
    userDetails?: any; // Replace 'any' with a more specific type if you know the structure of userDetails
}

interface AdminCheckResponse extends UserApiResponse {
    isAdmin?: boolean;
}

export const checkUsername = async (userName: string): Promise<UserApiResponse> => {
    try {
        const queryString = new URLSearchParams({ userName }).toString();
        const response = await fetch(`/api/checkUsername/?${queryString}`);
        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to check username');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while checking username');
        return { success: false, exists: false };
    }
};

export const updateUserCountry = async (
    authParams: any,
    countryCode: string
): Promise<UserApiResponse> => {
    try {
        const response = await fetch('/api/updateUserCountry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...authParams,
                countryCode,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update country');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating country');
        return { success: false };
    }
};

export const getUserDetails = async (
    authParams: any,
    username: string
): Promise<UserDetailsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            userName: username,
        }).toString();

        const response = await fetch(`/api/getUserDetails?${queryParams}`);
        const result: UserDetailsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch user details');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching user details');
        return { success: false };
    }
};

export const getFavoritePokemon = async (pokemonId: number): Promise<FavoritePokemonResponse> => {
    try {
        const response = await fetch(`/api/getPokemonDetails/${pokemonId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        if (response.ok) {
            return { success: true, favoritePokemon: data };
        } else {
            return { success: false, message: data.message || 'Failed to fetch favorite Pokemon' };
        }
    } catch (error) {
        return { success: false, message: 'An error occurred while fetching favorite Pokemon' };
    }
};

export const updateFavoritePokemon = async (
    authParams: any,
    pokemonId: number
): Promise<UserApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/updateFavoritePokemon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                pokemonId,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (result.success) {
            toast.success('Favorite Pokémon updated successfully');
        } else {
            toast.error('Failed to update favorite Pokémon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating favorite Pokémon');
        return { success: false };
    }
};

export const checkAdminStatus = async (authParams: any): Promise<AdminCheckResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
        }).toString();

        const response = await fetch(`/api/checkAdminStatus?${queryParams}`);

        const result: AdminCheckResponse = await response.json();

        if (!result.success) {
        }
        return result;
    } catch (error) {
        return { success: false, isAdmin: false };
    }
};
